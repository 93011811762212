/* eslint-disable react/jsx-no-bind */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Checkbox from '@mui/material/Checkbox';

import {
  selectCustomVirtualBackgrounds,
  selectHasBackgroundBlur,
  selectHasVirtualBackground,
  selectVirtualBackground,
  setCustomVirtualBackgrounds,
  setHasBackgroundBlur,
  setHasVirtualBackground,
  setVirtualBackground,
} from '../../redux/slices/room';
import { ImageUploader } from './ImageUploader';
import { BackgroundEffectsContainer, SettingsIcon } from './styled';
import { Wrapper } from './Wrapper';

async function loadBase64(name: string) {
  try {
    const response = await axios.get(
      `https://assets.streamgo.vc/virtual-backgrounds/${name}.txt`,
    );
    return response.data;
  } catch (error) {
    console.error('Failed to fetch the Base64 string:', error);
    return null;
  }
}

interface IVirtualBackgroundPicker {
  hasVideoTrack: boolean;
  isPreRoll: boolean;
}

export function BackgroundEffect({
  hasVideoTrack,
  isPreRoll,
}: IVirtualBackgroundPicker) {
  const dispatch = useDispatch();
  const [showConfig, setShowConfig] = useState(false);
  const hasBackgroundBlur = useSelector(selectHasBackgroundBlur);
  const hasVirtualBackground = useSelector(selectHasVirtualBackground);
  const customeVirtualBackgrounds = useSelector(selectCustomVirtualBackgrounds);
  const virtualBackground = useSelector(selectVirtualBackground);

  const handleSetHasBackgroundBlur = useCallback(
    (blur: boolean) => {
      dispatch(setHasBackgroundBlur(blur));
    },
    [dispatch],
  );

  const handleSetHasVirtualBackground = useCallback(
    (hasVb: boolean) => {
      dispatch(setHasVirtualBackground(hasVb));
    },
    [dispatch],
  );

  const handleSetVirtualBackground = useCallback(
    (background: string) => {
      dispatch(setVirtualBackground(background));
      setShowConfig(false);
    },
    [dispatch],
  );

  const [backgroundEffect, setBackgroundEffect] = useState(
    hasBackgroundBlur || hasVirtualBackground,
  );

  const setBackgroundEffectCheckbox = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const effect = ev.target.checked;

      if (!effect) {
        handleSetHasVirtualBackground(false);
        handleSetHasBackgroundBlur(false);
      }
      setShowConfig(effect);
      setBackgroundEffect(effect);
    },
    [handleSetHasBackgroundBlur, handleSetHasVirtualBackground],
  );

  const setVirtualBackgroundBoolen = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const virtualBackgroundChecked = ev.target.checked;
      if (virtualBackgroundChecked) {
        handleSetHasBackgroundBlur(false);
      }
      handleSetHasVirtualBackground(virtualBackgroundChecked);
    },
    [handleSetHasBackgroundBlur, handleSetHasVirtualBackground],
  );

  const setBackgroundBlur = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const blur = ev.target.checked;
      if (blur) {
        handleSetHasVirtualBackground(false);
      }
      setShowConfig(false);
      handleSetHasBackgroundBlur(blur);
    },
    [handleSetHasBackgroundBlur, handleSetHasVirtualBackground],
  );

  const handleImageUpload = useCallback(
    (base64Image: string) => {
      handleSetVirtualBackground(base64Image);
      dispatch(setCustomVirtualBackgrounds(base64Image));
    },
    [dispatch, handleSetVirtualBackground],
  );

  const handleLoadVirtualBackground = useCallback(
    (name: string) => {
      loadBase64(name).then(base64 => handleSetVirtualBackground(base64));
    },
    [handleSetVirtualBackground],
  );

  useEffect(() => {
    if (virtualBackground === '') {
      handleLoadVirtualBackground('splash8');
    }
  }, [handleLoadVirtualBackground, virtualBackground]);

  return !hasVideoTrack ? null : (
    <div style={{ textAlign: isPreRoll ? 'center' : 'left' }}>
      {isPreRoll ? (
        <section>
          <label htmlFor="background-effect"> Background effect</label>
          <Checkbox
            checked={backgroundEffect}
            id="background-effect"
            onChange={setBackgroundEffectCheckbox}
          />
          {backgroundEffect ? (
            <SettingsIcon onClick={() => setShowConfig(true)} />
          ) : null}
        </section>
      ) : null}
      {backgroundEffect || !isPreRoll ? (
        <Wrapper isPreRoll={isPreRoll} showConfig={showConfig}>
          <BackgroundEffectsContainer>
            <section>
              <label htmlFor="background-blur"> Background blur</label>
              <Checkbox
                checked={hasBackgroundBlur}
                id="background-blur"
                onChange={setBackgroundBlur}
              />
            </section>
            <section>
              <label htmlFor="virtual-background"> Virtual background</label>
              <Checkbox
                checked={hasVirtualBackground}
                id="virtual-background"
                onChange={setVirtualBackgroundBoolen}
              />
            </section>
            {hasVirtualBackground ? (
              <ImageUploader
                customeVirtualBackgrounds={customeVirtualBackgrounds}
                handleLoadVirtualBackground={handleLoadVirtualBackground}
                handleSetVirtualBackground={handleSetVirtualBackground}
                isPreRoll={isPreRoll}
                onImageUpload={handleImageUpload}
              />
            ) : null}
          </BackgroundEffectsContainer>
        </Wrapper>
      ) : null}
    </div>
  );
}
