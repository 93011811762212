import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

interface IWrapper {
  children: JSX.Element;
  isPreRoll: boolean;
  showConfig: boolean;
}

export const Wrapper = ({ children, isPreRoll, showConfig }: IWrapper) => {
  return isPreRoll ? (
    <Dialog
      maxWidth="xs"
      open={showConfig}
      sx={{
        '& .MuiDialog-paper': {
          textAlign: 'center',
          p: 3,
          maxWidth: '480px',
          width: '100%',
        },
      }}
    >
      <Typography component="h1" gutterBottom={true} variant="h5">
        Background Effect
      </Typography>
      {children}
    </Dialog>
  ) : (
    <>{children}</>
  );
};
