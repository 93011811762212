/* eslint-disable react/display-name */
import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { selectDynamicLayout } from '../../redux/slices/room';
import {
  useUpdateDynamicLayoutMutation,
  useUploadImageToS3Mutation,
} from '../../redux/streamGoApi';
import { DynamicLayoutSection } from './DynamicLayoutSection';
import {
  useBackgroundSettings,
  useBorderSettings,
  useLogoSettings,
  useNameTagSettings,
} from './hooks';
import { Container } from './styled';
import { IDynamicLayout } from './types';

interface IDynamicLayoutProps {
  roomId: string;
  setIsSaving: (loading: boolean) => void;
}

interface IDynamicLayoutRef {
  saveDynamicLayout: () => void;
}

export const DynamicLayout = forwardRef<IDynamicLayoutRef, IDynamicLayoutProps>(
  ({ roomId, setIsSaving }, ref) => {
    const [dynamicLayout, setDynamicLayout] = useState<IDynamicLayout>();
    const dl = useSelector(selectDynamicLayout);

    useEffect(() => {
      if (JSON.stringify(dl) !== JSON.stringify(dynamicLayout)) {
        setDynamicLayout(dl);
      }
    }, [dl, dynamicLayout]);

    const [uploadImageToS3] = useUploadImageToS3Mutation();
    const [updateDynamicLayout, { isLoading }] =
      useUpdateDynamicLayoutMutation();

    useEffect(() => {
      setIsSaving(isLoading);
    }, [isLoading, setIsSaving]);

    const { getNameTagModel, nameTagBlocks } = useNameTagSettings(
      dynamicLayout?.nameTags,
    );
    const { borderBlocks, getBorderModel } = useBorderSettings(
      dynamicLayout?.videoBorder,
    );
    const { backgroundBlocks, getBackgroundModel } = useBackgroundSettings({
      initialBackground: dynamicLayout?.background,
      roomId,
      uploadImageToS3,
    });
    const { getLogoModel, logoBlocks } = useLogoSettings({
      initialLogo: dynamicLayout?.logo,
      roomId,
      uploadImageToS3,
    });

    useImperativeHandle(ref, () => ({
      saveDynamicLayout() {
        const layout: IDynamicLayout = {
          nameTags: getNameTagModel(),
          videoBorder: getBorderModel(),
          background: getBackgroundModel(),
          logo: getLogoModel(),
        };
        updateDynamicLayout({ roomId, dynamicLayout: JSON.stringify(layout) });
      },
    }));

    if (dynamicLayout === undefined) {
      return <></>;
    }

    return (
      <Container>
        <DynamicLayoutSection blocks={nameTagBlocks} title="Name Tag" />
        <DynamicLayoutSection blocks={borderBlocks} title="Border" />
        <DynamicLayoutSection blocks={backgroundBlocks} title="Background" />
        <DynamicLayoutSection blocks={logoBlocks} title="Logo" />
      </Container>
    );
  },
);
