import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { selectDynamicLayout } from '../../../redux/slices/room';

const DynamicLogo = styled('img')(() => ({
  position: 'absolute',
  zIndex: 101,
  '&.topLeft': {
    left: '5%',
    top: '5%',
  },
  '&.topRight': {
    right: '5%',
    top: '5%',
  },
  '&.bottomLeft': {
    left: '5%',
    bottom: '5%',
  },
  '&.bottomRight': {
    right: '5%',
    bottom: '5%',
  },
}));

export const Logo = () => {
  const dynamicLayout = useSelector(selectDynamicLayout) ?? {};

  return (
    <>
      {dynamicLayout?.logo ? (
        <DynamicLogo
          alt="logo"
          className={`${dynamicLayout.logo.position}`}
          src={dynamicLayout.logo.url}
          style={{ width: `${dynamicLayout.logo.width.toString()}%` }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
