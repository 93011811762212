/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { Participant } from 'livekit-client';
import FileDownload from '@mui/icons-material/FileDownload';
import FileUpload from '@mui/icons-material/FileUpload';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  IConferenceParticipant,
  IRoom,
  useUploadSpeedTestResultsMutation,
} from '../../../redux/streamGoApi';

interface ISpeedTestIcon {
  conferenceParticipant?: IConferenceParticipant;
  isModerator?: boolean;
  participant?: Participant;
  streamGoRoom?: IRoom;
}

export const FlexBox: any = styled(Box, {
  shouldForwardProp: props => props !== 'isModerator',
})(() => ({
  display: 'flex',
  '& svg': {
    cursor: 'pointer',
  },
}));

export const SpeedBox: any = styled(FlexBox)({
  flexDirection: 'column',
  '& > *:first-of-type': {
    top: '-3px',
    position: 'relative',
    fontSize: '12px',
  },
  '& > *:last-of-type': {
    top: '-7px',
    position: 'relative',
    fontSize: '7px',
    left: '1px',
  },
});

export const downloadSpeedClass = (downloadSpeed?: number) => {
  if (!downloadSpeed) {
    return '';
  }

  if (downloadSpeed > 20) {
    return 'very-good';
  }
  if (downloadSpeed > 10) {
    return 'good';
  }
  if (downloadSpeed > 5) {
    return 'average';
  }
  return 'bad';
};

export const uploadSpeedClass = (uploadSpeed?: number) => {
  if (!uploadSpeed) {
    return '';
  }

  if (uploadSpeed > 10) {
    return 'very-good';
  }
  if (uploadSpeed > 8) {
    return 'good';
  }
  if (uploadSpeed > 4) {
    return 'average';
  }
  return 'bad';
};

export const SpeedTestIcon = ({
  conferenceParticipant,
  isModerator,
  participant,
  streamGoRoom,
}: ISpeedTestIcon) => {
  const [uploadSpeedTestMutation] = useUploadSpeedTestResultsMutation();
  const isEditAllowed = participant?.isLocal || isModerator;

  const resetDownloadSpeed = useCallback(() => {
    if (streamGoRoom && conferenceParticipant && isEditAllowed) {
      const speedTestResults = {
        roomId: streamGoRoom.RoomName,
        conference: {
          confId: streamGoRoom.ConferenceId,
        },
        Participant: {
          SpeedTest: {
            DownloadSpeed: 0,
            UploadSpeed: 0,
          },
          UserId: conferenceParticipant.ParticipantId,
        },
      };

      uploadSpeedTestMutation(speedTestResults);
    }
  }, [
    conferenceParticipant,
    isEditAllowed,
    streamGoRoom,
    uploadSpeedTestMutation,
  ]);

  return (
    <FlexBox
      className={isEditAllowed ? '' : 'disabled'}
      isModerator={isModerator}
      onClick={resetDownloadSpeed}
      title={isModerator ? 'click to refresh download speed' : ''}
    >
      <FlexBox
        className={downloadSpeedClass(conferenceParticipant?.DownloadSpeed)}
      >
        <FileDownload onClick={resetDownloadSpeed} />
        <SpeedBox>
          <Box>
            {conferenceParticipant?.DownloadSpeed
              ? conferenceParticipant?.DownloadSpeed.toFixed(0)
              : '...'}
          </Box>
          <Box>Mbps</Box>
        </SpeedBox>
      </FlexBox>
      <FlexBox className={uploadSpeedClass(conferenceParticipant?.UploadSpeed)}>
        <FileUpload />
        <SpeedBox>
          <Box>
            {conferenceParticipant?.UploadSpeed
              ? conferenceParticipant?.UploadSpeed.toFixed(0)
              : '...'}
          </Box>
          <Box>Mbps</Box>
        </SpeedBox>
      </FlexBox>
    </FlexBox>
  );
};
