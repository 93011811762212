import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectRoomName } from '../../redux/slices/room';
import { Loading } from '../Form';
import { Page } from './enums';
import { Login } from './Login';
import { PreLogin } from './PreLogin';

interface ILoginPage {
  defaultPage?: Page;
}

export const LoginPage = ({ defaultPage = Page.PreLogin }: ILoginPage) => {
  const roomid = useSelector(selectRoomName);
  const [page, setPage] = useState(defaultPage);

  if (!roomid) {
    return <Loading />;
  }

  return page === Page.PreLogin ? (
    <PreLogin roomId={roomid} setPage={setPage} />
  ) : (
    <Login roomId={roomid} setPage={setPage} />
  );
};
