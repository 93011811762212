import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectIsLoggedOut,
  selectShowLoginPage,
} from '../../redux/slices/auth';
import { selectRoomName } from '../../redux/slices/room';
import { RootState } from '../../redux/store';
import { ThankYou } from '../Form';
import { LoginPage } from '../Login';
import { Page } from '../Login/enums';
import { ChooseRoom, Room } from '../Rooms';

export const Conference = () => {
  const roomid = useSelector(selectRoomName) ?? '';
  const isLoggedOut = useSelector(selectIsLoggedOut);
  const showLoginPage = useSelector(selectShowLoginPage);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  if (!roomid) {
    return <ChooseRoom />;
  }
  if (isLoggedOut) {
    return <ThankYou />;
  }
  if (showLoginPage) {
    return <LoginPage defaultPage={Page.Login} />;
  }
  if (isAuthenticated) {
    return <Room />;
  }
  return <LoginPage />;
};
