import { useCallback } from 'react';
import { LiveKitRoom } from '@livekit/components-react';
import EgressHelper from '@livekit/egress-sdk';
import { CompositeTemplate } from './CompositeTemplate';
import { ErrorBoundary } from './ErrorBoundary';

interface IRoomPage {
  token?: string;
  url?: string;
}

export function RoomPage({ token: lkToken, url: lkUrl }: IRoomPage) {
  const handleError = useCallback((error: Error) => {
    console.log(error);
  }, []);

  if (typeof window === 'undefined') {
    return <div className="lk-room-container"></div>;
  }

  const url = lkUrl ?? EgressHelper.getLiveKitURL();
  const token = lkToken ?? EgressHelper.getAccessToken();

  if (!url || !token) {
    return <div className="error">missing required params url and token</div>;
  }

  return (
    <LiveKitRoom onError={handleError} serverUrl={url} token={token}>
      <ErrorBoundary>
        <CompositeTemplate />
      </ErrorBoundary>
    </LiveKitRoom>
  );
}
