import { useSelector } from 'react-redux';
import {
  TrackReference,
  TrackReferenceOrPlaceholder,
} from '@livekit/components-react';
import { selectConferenceParticipants } from '../../../redux/slices/room';

interface IUseGetPinnedPresenters {
  tracks: Array<TrackReferenceOrPlaceholder>;
}

export const useGetPinnedPresenters = ({
  tracks,
}: IUseGetPinnedPresenters): TrackReference[] => {
  const streamGoParticipants = useSelector(selectConferenceParticipants);
  const pinnedPresenters = streamGoParticipants?.filter(x => x.Pinned);
  const pinnedTracks = pinnedPresenters.length
    ? tracks.filter(track =>
        pinnedPresenters.find(cp => cp.ParticipantId === track.participant.sid),
      )
    : tracks;

  return pinnedTracks as TrackReference[];
};
