import SentimentSatisfiedAlt from '@mui/icons-material/SentimentSatisfiedAlt';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export const ThankYou = () => {
  return (
    <Container maxWidth="sm" sx={{ height: '100%' }}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight="100%"
      >
        <SentimentSatisfiedAlt sx={{ fontSize: 120, mb: 2 }} />
        <Typography component="h1" gutterBottom={true} variant="h5">
          Thank You for joining
        </Typography>
      </Box>
    </Container>
  );
};
