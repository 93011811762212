import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

export interface IErrorDialog {
  children: JSX.Element | JSX.Element[] | string;
  okButtonText?: string;
  onConfirm: () => void;
  open: boolean;
  title?: string;
}

export const ErrorDialog = ({
  children,
  okButtonText = 'Ok',
  onConfirm,
  open,
  title = 'Oops! Something went wrong.',
}: IErrorDialog) => {
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          textAlign: 'center',
          p: 2,
          pt: 3,
        },
      }}
    >
      <Typography component="h1" gutterBottom={true} variant="h4">
        {title}
      </Typography>
      <Typography align="center" sx={{ mt: 1 }} variant="body1">
        {children}
      </Typography>

      <DialogActions>
        <Button color="error" onClick={onConfirm} variant="contained">
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
