/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const SgContainer: any = styled(Container)`
  max-width: 300px;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SgBox: any = styled(Box)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.palette.background.paper};
    padding: ${theme.spacing(3)};
    border-radius: ${theme.shape.borderRadius}px;
    box-shadow: ${theme.shadows[1]};
      max-width: 300px;
  `}
`;

export const SgTextField = styled(TextField)`
  ${({ theme }) => `
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${theme.palette.background.default};
    margin-top: ${theme.spacing(1)};
    & .MuiInputBase-input {
      padding: 8px 14px;
      line-height: 1.5;
    }
    & .MuiInputLabel-root {
      line-height: 1;
    }
    & .MuiInputLabel-formControl {
      top: -4px;
    }
    & .MuiInputLabel-shrink {
      top: 4px;
    }
  `}
`;
