import { Track } from 'livekit-client';

interface ICalculateVideoWidths {
  dimensions?: Track.Dimensions;
  imageDimensions?: Track.Dimensions;
  rightRatio: string;
}

export const calculateVideoWidths = ({
  dimensions,
  imageDimensions,
  rightRatio,
}: ICalculateVideoWidths) => {
  // if we have ref dimensions, then return null
  if (!dimensions) {
    return {
      left: null,
      right: null,
    };
  }

  const aspectLeft = dimensions.width / dimensions.height;
  let aspectRight = rightRatio === '16_9' ? 16 / 9 : 1;

  if (imageDimensions?.width) {
    aspectRight = imageDimensions.width / imageDimensions.height;
  }

  const widthLeft = aspectLeft;
  const widthRight = aspectRight;
  const totalWidth = widthLeft + widthRight;

  const percentLeft = (widthLeft / totalWidth) * 100;
  const percentRight = (widthRight / totalWidth) * 100;

  return {
    left: percentLeft,
    right: percentRight,
  };
};
