import { Participant } from 'livekit-client';
import Person from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { IConferenceParticipant } from '../../redux/streamGoApi';
import { ParticipantImage } from '../Form/ParticipantImage';

interface IParticipantAvatar {
  participant: Participant;
  streamGoParticipants: IConferenceParticipant[];
}

export const ParticipantAvatar = ({
  participant,
  streamGoParticipants,
}: IParticipantAvatar) => {
  const streamGoParticipant = streamGoParticipants.find(
    x => x.ParticipantId === participant.sid,
  );

  return (
    <ListItemAvatar>
      <Avatar>
        {streamGoParticipant?.StreamGoImg ||
        streamGoParticipant?.ParticipantImgUrl ? (
          <ParticipantImage streamGoParticipant={streamGoParticipant} />
        ) : (
          <Person />
        )}
      </Avatar>
    </ListItemAvatar>
  );
};
