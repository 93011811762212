import React from 'react';
import { useSelector } from 'react-redux';
import { Participant } from 'livekit-client';
import { styled } from '@mui/material/styles';
import { selectDynamicLayout } from '../../../redux/slices/room';

interface INameTag {
  participant: Participant;
}

const NameTagTyped = styled('div')({
  position: 'absolute',
  left: 0,
  bottom: 0,
  background: '#0057ff',
  padding: '4px 20px',
  color: 'white',
  fontWeight: 'bold',
  fontSize: '1.2em',
});

export const NameTag = ({ participant }: INameTag) => {
  const dynamicLayout = useSelector(selectDynamicLayout) ?? {};

  const NameStrapComp = () =>
    dynamicLayout?.nameTags ? (
      <NameTagTyped
        className="name-strap"
        style={{
          background: dynamicLayout?.nameTags?.background,
          color: dynamicLayout?.nameTags?.color,
          margin: dynamicLayout?.videoBorder?.width,
        }}
      >
        {participant.name || participant.identity}
      </NameTagTyped>
    ) : null;

  return (
    <>
      {/* {sgParticipant ? (
        <div
          className="name-strap-container"
          style={{ height: webcam ? `${videoHeight}vw` : 'undefined' }}
        >
          <NameStrapComp />
        </div>
      ) : ( */}
      <NameStrapComp />
      {/* )} */}
    </>
  );
};
