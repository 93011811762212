import { useEffect, useState } from 'react';
import { WidgetState } from '@livekit/components-core';
import { useMaybeLayoutContext } from '@livekit/components-react';

interface IWidgetManager {
  widgetState: WidgetState;
}

export const WidgetManager = ({ widgetState }: IWidgetManager) => {
  const [oldWidget, setOldWidget] = useState<WidgetState>(widgetState);

  useEffect(() => {
    setOldWidget(widgetState);
  }, [widgetState]);

  const layoutContext = useMaybeLayoutContext();
  useEffect(() => {
    if (
      widgetState?.showChat &&
      !oldWidget.showChat &&
      oldWidget.showSettings
    ) {
      layoutContext?.widget?.dispatch?.({ msg: 'toggle_settings' });
    }

    if (
      widgetState?.showSettings &&
      !oldWidget.showSettings &&
      oldWidget.showChat
    ) {
      layoutContext?.widget?.dispatch?.({ msg: 'hide_chat' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layoutContext?.widget?.dispatch,
    layoutContext?.widget.state?.showChat,
    layoutContext?.widget.state?.showSettings,
    oldWidget.showChat,
    oldWidget.showSettings,
    widgetState,
  ]);

  return null;
};
