import { useEffect } from 'react';
import { useGetAcceesTokenLiveKitMutation } from '../../../redux/streamGoApi';

export const useGetRoomToken = (roomId?: string, username?: string) => {
  const [getAccessToken, { data: accessToken, isError, isLoading }] =
    useGetAcceesTokenLiveKitMutation();

  useEffect(() => {
    if (roomId && username) {
      getAccessToken({
        roomId,
        username,
      });
    }
  }, [roomId, username, getAccessToken]);

  return { accessToken, isLoading, isError };
};
