import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Track } from 'livekit-client';
import {
  TrackReferenceOrPlaceholder,
  useTracks,
} from '@livekit/components-react';
import { selectConferenceParticipants } from '../../../redux/slices/room';
import { IConferenceParticipant } from '../../../redux/streamGoApi';
import { excludedPresenters } from '../../../utils/constants';
import { IStreamingViewOptions } from '../types';

export const hasSgImage = (
  trackReference: TrackReferenceOrPlaceholder,
  streamGoParticipants: IConferenceParticipant[],
) => {
  const streamGoParticipant = streamGoParticipants.find(
    x => x.ParticipantId === trackReference?.participant.sid,
  );

  return streamGoParticipant?.ParticipantImgUrl;
};

export const isTrackReferenceValid = (
  trackReference: TrackReferenceOrPlaceholder,
  streamGoParticipants: IConferenceParticipant[],
) =>
  (trackReference?.source === 'screen_share' ||
    trackReference?.participant.isCameraEnabled ||
    hasSgImage(trackReference, streamGoParticipants)) &&
  !trackReference.participant.isLocal &&
  !excludedPresenters.find(name =>
    trackReference.participant.identity?.toLowerCase().startsWith(name),
  ) &&
  (trackReference.publication?.kind === 'video' ||
    trackReference.source === Track.Source.Camera ||
    trackReference.source === Track.Source.ScreenShare);

export const useGetRoomStreamingDetails = (
  hasPPT = false,
): IStreamingViewOptions => {
  const [hasScreenShare, setHasScreenShare] = useState(false);
  const [hasSgVm, setHasSgVm] = useState(false);

  const screenshareTracks = useTracks([Track.Source.ScreenShare], {
    onlySubscribed: true,
  });

  const streamGoParticipants = useSelector(selectConferenceParticipants);

  const videoTracks = useTracks([
    { source: Track.Source.Camera, withPlaceholder: true },
  ]);

  const connectedVideoTracks = videoTracks.filter(track =>
    isTrackReferenceValid(track, streamGoParticipants),
  );

  const sgVmVideoTracks = videoTracks.filter(
    track =>
      track.participant.name?.toLowerCase().startsWith('sg_vm') &&
      track.publication &&
      track.participant.isCameraEnabled,
  );

  useEffect(() => {
    setHasSgVm(sgVmVideoTracks.length > 0);
  }, [sgVmVideoTracks.length]);

  useEffect(() => {
    setHasScreenShare(
      Boolean(screenshareTracks.length > 0 && screenshareTracks[0].publication),
    );
  }, [screenshareTracks]);

  return {
    sgVmVideoTracks,
    connectedVideoTracks,
    screenshareTracks,
    hasScreenShare,
    hasSgVm,
    hasPPT,
  };
};
