import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { setRoomName } from '../../redux/slices/room';
import { RootState } from '../../redux/store';
import { useCheckMutation } from '../../redux/streamGoApi';
import { Error, Loading, Logo, SgBox, SgContainer } from '../Form';
import { Page } from './enums';

interface IPreLogin {
  roomId: string;
  setPage: (page: Page) => void;
}

export const PreLogin = ({ roomId, setPage }: IPreLogin) => {
  const dispatch = useDispatch();
  const handleSetPage = useCallback(() => setPage(Page.Login), [setPage]);
  const [check, { data, error }] = useCheckMutation();

  useEffect(() => {
    check({ roomId });
  }, [check, roomId]);

  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (data && !isAuthenticated) {
      setPage(Page.Login);
    }
  }, [data, isAuthenticated, setPage]);

  const handleJoinRoom = useCallback(() => {
    if (isAuthenticated) {
      dispatch(setRoomName(roomId));
    }
  }, [dispatch, isAuthenticated, roomId]);

  if (!data) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <SgContainer>
      <SgBox>
        <Logo />
        <Button
          color="primary"
          fullWidth={true}
          onClick={handleJoinRoom}
          type="button"
          variant="contained"
        >
          Join room
        </Button>
        <Typography color="text.secondary" sx={{ mt: 2 }} variant="body2">
          Are you a moderator?
        </Typography>
        <Button
          color="secondary"
          fullWidth={true}
          onClick={handleSetPage}
          sx={{ mt: 1 }}
          type="button"
          variant="contained"
        >
          Sign In
        </Button>
      </SgBox>
    </SgContainer>
  );
};
