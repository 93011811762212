/* eslint-disable max-len */
import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { useErrorDialog } from '../../hooks';
import { setRoomName } from '../../redux/slices/room';
import { Logo, SgBox, SgContainer, SgTextField } from '../Form';

interface IFormData {
  roomId: string;
}

interface IChooseRoom {
  isAltView?: boolean;
  setIsAltView?: (altView: boolean) => void;
}

export const ChooseRoom = ({ isAltView, setIsAltView }: IChooseRoom) => {
  const dispatch = useDispatch();
  const { Error, showError } = useErrorDialog(
    'Enabling API calls will enable all api calls normally performed by the LiveKit egress stream.  These can interfeer with the exisitng egress if there is one running, only enable these if the mixer view with LiveKit is not working/streaming',
    'Warning - please read',
  );
  const [formData, setFormData] = useState<IFormData>({
    roomId: '',
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleCheckChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      if (!checked) {
        showError(true);
      }
      setIsAltView?.(checked);
    },
    [setIsAltView, showError],
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const { roomId } = formData;
      dispatch(setRoomName(roomId));
    },
    [dispatch, formData],
  );

  return (
    <SgContainer>
      <SgBox>
        <Logo />
        <Typography gutterBottom={true} variant="subtitle1">
          Please choose a room name
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <SgTextField
            autoComplete="name"
            fullWidth={true}
            id="roomId"
            label="Room name"
            margin="normal"
            name="roomId"
            onChange={handleChange}
            required={true}
            value={formData.roomId}
            variant="outlined"
          />
          {isAltView !== undefined ? (
            <section style={{ textAlign: 'center' }}>
              <label htmlFor="audio-only">Disable API calls</label>
              <Checkbox
                checked={isAltView}
                onChange={handleCheckChanged}
                size="large"
              />
            </section>
          ) : null}
          <Box display="flex" gap={2} sx={{ mt: 2 }} width="100%">
            <Button
              color="primary"
              sx={{ width: '100%' }}
              type="submit"
              variant="contained"
            >
              Go
            </Button>
          </Box>
        </form>
      </SgBox>
      <Error />
    </SgContainer>
  );
};
