import { StreamingViewType } from '../../../redux/streamGoApi/enums';
import {
  otherStreamingViews,
  priorityStreamingViews,
  screenshareViews,
  slidesViews,
  streamingViews,
} from '../constants';
import { IStreamingViewOptions } from '../types';
import { getStreamingViews } from './getStreamingViews';

interface IGetStreamingViewOptions {
  selectedStreamingView?: number;
  setSelectedStreamingView: (view: number) => void;
  streamingViewOptions: IStreamingViewOptions;
  streamingViewType: StreamingViewType;
  width: number;
}

export const getStreamingViewOptions = ({
  selectedStreamingView,
  setSelectedStreamingView,
  streamingViewOptions,
  streamingViewType,
  width,
}: IGetStreamingViewOptions) => {
  const { hasPPT, hasScreenShare, hasSgVm } = streamingViewOptions;
  const showAll = streamingViewType === StreamingViewType.StreamingViewDefault;
  const screenShareEnabled = hasScreenShare;

  let streamingViewsFiltered = Object.values(streamingViews);

  if (!showAll) {
    streamingViewsFiltered =
      (hasPPT && showAll) || hasSgVm
        ? streamingViewsFiltered
        : streamingViewsFiltered.filter(x => !slidesViews.find(y => y === x));

    streamingViewsFiltered = screenShareEnabled
      ? streamingViewsFiltered
      : streamingViewsFiltered.filter(
          x => !screenshareViews.find(y => y === x),
        );
  }

  if (showAll && !hasPPT && !hasSgVm) {
    streamingViewsFiltered = streamingViewsFiltered.filter(
      x => !slidesViews.find(y => y === x),
    );
  }

  const priorityView = getStreamingViews({
    sectionViews: priorityStreamingViews,
    filteredViews: streamingViewsFiltered,
    width,
    setSelectedStreamingView,
    selectedStreamingView,
  });

  const otherView = getStreamingViews({
    sectionViews: otherStreamingViews,
    filteredViews: streamingViewsFiltered,
    width,
    setSelectedStreamingView,
    selectedStreamingView,
  });

  return { priorityView, otherView };
};
