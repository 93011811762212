/* eslint-disable @typescript-eslint/no-explicit-any */
import { VideoTrack } from '@livekit/components-react';
import Box from '@mui/material/Box';
import { css, styled } from '@mui/material/styles';

export const SideBySideViewVideoContainer: any = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  > div {
    display: flex;
  }
`;

export interface ISideBySideViewVideoTrack {
  ratio?: string;
  width?: number;
}

export const SideBySideViewVideoTrack: any = styled(VideoTrack)(
  (props: ISideBySideViewVideoTrack) => css`
    object-fit: cover !important;
    object-position: center !important;
    max-height: 100% !important;
    width: ${props.width
      ? `calc(100vw / 100 * ${props.width}) !important`
      : 'unset !important'};
    height: ${props.ratio
      ? `calc((100vw / 100 * ${props.width}) / ${props.ratio.split('_')[0]} * ${
          props.ratio.split('_')[1]
        }) !important`
      : 'auto !important'};
  `,
);

export const SideBySideViewVideoOrImageTrack = styled(
  'div',
)<ISideBySideViewVideoTrack>(
  ({ ratio, width }) => css`
    .videoCam {
      position: absolute;
    }

    object-fit: cover !important;
    object-position: center !important;
    max-height: 100% !important;
    width: ${width
      ? `calc(100vw / 100 * ${width}) !important`
      : 'unset !important'};
    height: ${ratio
      ? `calc((100vw / 100 * ${width}) / ${ratio.split('_')[0]} * ${
          ratio.split('_')[1]
        }) !important`
      : 'auto !important'};
  `,
);
