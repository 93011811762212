/* eslint-disable max-len */
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { RoomConnectOptions } from 'livekit-client';

import { LocalUserChoices } from '@livekit/components-react';
import { addLiveKitToken } from '../../../redux/slices/auth';
import { useGetRoomConfig } from './useGetLiveKitRoomConfig';
import { useGetRoomToken } from './useGetRoomToken';

interface IUseGetRoomSettings {
  hq?: boolean;
  roomName?: string;
  userChoices?: LocalUserChoices;
}

export const useGetRoomSettings = ({
  hq = false,
  roomName,
  userChoices,
}: IUseGetRoomSettings) => {
  const dispatch = useDispatch();
  const token = useGetRoomToken(roomName, userChoices?.username);
  const room = useGetRoomConfig({
    hq,
    userChoices,
  });

  const connectOptions = useMemo((): RoomConnectOptions => {
    return {
      autoSubscribe: true,
    };
  }, []);

  useEffect(() => {
    if (token.accessToken) {
      dispatch(addLiveKitToken(token.accessToken));
    }
  }, [dispatch, token.accessToken]);

  return {
    room,
    connectOptions,
  };
};
