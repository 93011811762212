import React, { useState, useRef, useCallback } from 'react';
import { TwitterPicker } from 'react-color';
import { styled } from '@mui/material/styles';
import { useOutsideAlerter } from '../../hooks';
import { ColorPickerButton, ColorPickerDropdown } from './styled';

interface IColorPicker {
  colorSelected: string;
  setColor: (color: string) => void;
}

export const ColourPickerContainer = styled('div')({
  position: 'relative',
  marginRight: '10px',
});

export const ColorPicker = ({ colorSelected, setColor }: IColorPicker) => {
  const pickerRef = useRef(null);
  const [active, setActive] = useState(false);
  useOutsideAlerter(pickerRef, () => setActive(false));

  const handleSetActive = useCallback(() => setActive(true), []);

  const handleChangeColor = useCallback(
    (color: { hex: string }) => {
      setActive(false);
      setColor(color.hex);
    },
    [setColor],
  );

  return (
    <ColourPickerContainer>
      <ColorPickerButton
        onClick={handleSetActive}
        style={{ backgroundColor: colorSelected }}
      />
      {active ? (
        <ColorPickerDropdown ref={pickerRef}>
          <TwitterPicker
            color={colorSelected}
            colors={[
              '#FFFFFF',
              '#000000',
              '#FF6900',
              '#FCB900',
              '#7BDCB5',
              '#00D084',
              '#8ED1FC',
              '#0693E3',
              '#ABB8C3',
              '#EB144C',
              '#F78DA7',
              '#9900EF',
            ]}
            onChangeComplete={handleChangeColor}
            triangle="top-right"
          />
        </ColorPickerDropdown>
      ) : null}
    </ColourPickerContainer>
  );
};
