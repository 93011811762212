/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAltView } from '../../../redux/slices/auth';
import { RootState } from '../../../redux/store';
import { useUpdateStreamingViewMutation } from '../../../redux/streamGoApi';
import { StreamingView } from '../../../redux/streamGoApi/enums';
import { screenshareViews, slidesViews } from '../constants';
import { useGetRoomStreamingDetails } from './useGetRoomStreamingDetails';

interface IUseIsStreamingModeValid {
  isMediaMode: boolean;
  roomId: string;
  streamingView?: StreamingView;
}

const isScreenshareView = (streamingView?: StreamingView) =>
  streamingView && screenshareViews.includes(streamingView);

const isSlidesView = (streamingView?: StreamingView) =>
  streamingView && slidesViews.includes(streamingView);

const DEFAULT_STREAMING_VIEW = StreamingView.WebCamsActiveSpeaker;

interface ICheckScreenshareViewValid {
  hasScreenShare: boolean;
  newStreamingView?: StreamingView;
  streamingView?: StreamingView;
  streamingViewHistoryFiltered: StreamingView[];
}

const checkScreenshareViewValid = ({
  hasScreenShare,
  newStreamingView,
  streamingView,
  streamingViewHistoryFiltered,
}: ICheckScreenshareViewValid) => {
  const viewIsScreenshare = isScreenshareView(streamingView);
  // if the platform is set to screenshare view but there are no screenshares available,
  // revert to previous streaming view
  if (!hasScreenShare) {
    // filter out any screenshares from the history
    streamingViewHistoryFiltered = streamingViewHistoryFiltered.filter(
      view =>
        !screenshareViews.find(screenshareView => screenshareView === view),
    );

    if (viewIsScreenshare) {
      // take the latest other one
      newStreamingView = streamingViewHistoryFiltered.pop();
      console.log('change screenshare view: ', newStreamingView);
    }
  }
  return { streamingViewHistoryFiltered, newStreamingView };
};

interface ICheckSlidesViewValid {
  hasSgVm: boolean;
  newStreamingView?: StreamingView;
  streamingView?: StreamingView;
  streamingViewHistoryFiltered: StreamingView[];
}

const checkSlidesViewValid = ({
  hasSgVm,
  newStreamingView,
  streamingView,
  streamingViewHistoryFiltered,
}: ICheckSlidesViewValid) => {
  const viewIsSlides = isSlidesView(streamingView);

  // if the platform is set to slides mode but there are no slides then revert to the previous setting available
  if (!hasSgVm) {
    // filter out any screenshares from the history
    streamingViewHistoryFiltered = streamingViewHistoryFiltered.filter(
      view => !slidesViews.find(slidesView => slidesView === view),
    );

    if (viewIsSlides) {
      newStreamingView = streamingViewHistoryFiltered.pop();
      console.log('change slides view: ', newStreamingView);
    }
  }

  // TODO - do we need a retry here?
  return { streamingViewHistoryFiltered, newStreamingView };
};

export const useIsStreamingModeValid = ({
  isMediaMode = false,
  roomId,
  streamingView,
}: IUseIsStreamingModeValid) => {
  const isAltView = useSelector(selectIsAltView);
  const { hasScreenShare, hasSgVm } = useGetRoomStreamingDetails();
  const [updateStreamingView] = useUpdateStreamingViewMutation();
  const { streamingViewHistory } = useSelector(
    (state: RootState) => state.streamingViewHistory,
  );

  useEffect(() => {
    const postRollOrPreroll =
      streamingView &&
      [StreamingView.PostRoll, StreamingView.PreRoll].includes(streamingView);

    // don't try and switch for the following
    // is there is no streaming view
    // if the streaming view is on post roll or preroll
    // if it is on media mode
    // if there is no roomId
    // if alt view don't bother trying to adjust
    if (
      isAltView ||
      !streamingView ||
      postRollOrPreroll ||
      isMediaMode ||
      !roomId
    ) {
      return;
    }

    let newStreamingView: StreamingView | undefined = streamingView;
    let streamingViewHistoryFiltered = [...streamingViewHistory];

    ({ newStreamingView, streamingViewHistoryFiltered } =
      checkScreenshareViewValid({
        streamingView,
        hasScreenShare,
        streamingViewHistoryFiltered,
        newStreamingView,
      }));

    ({ newStreamingView, streamingViewHistoryFiltered } = checkSlidesViewValid({
      streamingView,
      hasSgVm,
      streamingViewHistoryFiltered,
      newStreamingView,
    }));

    if (!newStreamingView) {
      newStreamingView = DEFAULT_STREAMING_VIEW;
    }

    if (newStreamingView !== streamingView) {
      updateStreamingView({
        roomId,
        StreamingViewAlt: StreamingView.Unset,
        StreamingView: newStreamingView,
      });
    }
  }, [
    hasScreenShare,
    hasSgVm,
    isAltView,
    isMediaMode,
    roomId,
    streamingView,
    streamingViewHistory,
    updateStreamingView,
  ]);
};
