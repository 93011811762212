/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { postrollUrl } from '../../../../utils/constants';
import { useSetLiveView } from '../../hooks/useSetLiveView';
import { FullScreen, FullscreenVideo } from '../../Styled';

interface IPostrollView {
  locale?: string;
  webcamGoPostRollVideoUrl?: string;
}

export const PostrollView = ({
  locale,
  webcamGoPostRollVideoUrl,
}: IPostrollView) => {
  const videoUrl = locale
    ? postrollUrl.replace('[[locale]]', locale)
    : webcamGoPostRollVideoUrl;

  useSetLiveView({
    component: 'postroll',
    videoTracks: [],
  });

  return (
    <FullScreen>
      {videoUrl && (
        <FullscreenVideo autoPlay={true} id="postRollVideo" loop={true}>
          <source src={videoUrl} type="video/mp4" />
        </FullscreenVideo>
      )}
    </FullScreen>
  );
};
