import { useGetPlatformEventDetailsQuery } from '../../../redux/platformApi';
import { useGetRoomDetailsQuery } from '../../../redux/streamGoApi';
import { useGetSettings } from '../../Templates';

export const useGetRoom = (roomId: string) => {
  const { pollInterval } = useGetSettings();
  const pollingInterval = pollInterval ? Number(pollInterval) : 5000;
  return useGetRoomDetailsQuery({ roomId }, { pollingInterval, skip: !roomId });
};

export const useGetPlatformEventDetails = (
  eventId?: number,
  pollingInterval = 5000,
) =>
  useGetPlatformEventDetailsQuery(undefined, {
    pollingInterval,
    skip: !eventId,
  });
