import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { selectDynamicLayout } from '../../../redux/slices/room';

interface IBackgroundImageProps {
  backgroundColour?: string;
  backgroundUrl?: string;
  children: JSX.Element[];
}

export const BackgroundStyled = styled('div')(() => ({
  backgroundSize: 'cover!important',
  backgroundPosition: 'center!important',
}));

export const BackgroundImage = ({
  backgroundColour,
  backgroundUrl,
  children,
}: IBackgroundImageProps) => {
  const dynamicLayout = useSelector(selectDynamicLayout) ?? {};

  const backgroundImage =
    dynamicLayout.background?.backgroundUrl || backgroundUrl;
  const backgroundCol =
    dynamicLayout.background?.backgroundColour || backgroundColour;

  return (
    <BackgroundStyled
      style={{
        background: backgroundImage
          ? `url(${backgroundImage})`
          : backgroundCol || 'black',
      }}
    >
      {children}
    </BackgroundStyled>
  );
};
