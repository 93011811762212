/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LiveKitRoom } from '@livekit/components-react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  logout,
  selectLiveKitRoomProps,
  selectLiveKitToken,
} from '../../redux/slices/auth';
import { useGetRoomSettings } from './utils';

export const CurrentUserLive: any = styled(Box)`
  color: red;
  position: absolute;
  top: 5px;
  left: 10px;

  > svg {
    position: relative;
    top: 6px;
  }
`;

const VideoConferenceStyled = styled(Box)(
  () => `
    width: 100%;
    height: 100%;
    `,
);

const defaultUrl = 'wss://streamgo-l32cq6mf.livekit.cloud';

interface ILiveKitRoomStreamGo {
  children: JSX.Element;
  onDisconnected?: () => void;
}

export const LiveKitRoomStreamGo = ({
  children,
  onDisconnected,
}: ILiveKitRoomStreamGo) => {
  const dispatch = useDispatch();
  const onLeave = useCallback(() => {
    if (onDisconnected) {
      onDisconnected();
    } else {
      dispatch(logout());
    }
  }, [dispatch, onDisconnected]);

  const liveKitUrl = process.env.NEXT_PUBLIC_LIVEKIT_URL ?? defaultUrl;

  const { hq, roomName, userChoices } = useSelector(selectLiveKitRoomProps) ?? {
    roomName: undefined,
    userChoices: undefined,
  };

  const { connectOptions, room } = useGetRoomSettings({
    hq,
    roomName,
    userChoices,
  });

  const token = useSelector(selectLiveKitToken);

  return (
    <main data-lk-theme="default">
      <VideoConferenceStyled>
        <>
          {liveKitUrl && token ? (
            <LiveKitRoom
              audio={userChoices?.audioEnabled}
              connectOptions={connectOptions}
              onDisconnected={onLeave}
              room={room}
              serverUrl={liveKitUrl}
              token={token}
              video={userChoices?.videoEnabled}
            >
              {children}
            </LiveKitRoom>
          ) : (
            <>{children}</>
          )}
        </>
      </VideoConferenceStyled>
    </main>
  );
};
