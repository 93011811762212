/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable max-lines */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import {
  FormEvent,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isFirefox, isSafari } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { facingModeFromLocalTrack, Track } from 'livekit-client';
import type { LocalAudioTrack, LocalVideoTrack } from 'livekit-client';
import type { LocalUserChoices } from '@livekit/components-core';
import { log, defaultUserChoices } from '@livekit/components-core';
import {
  MediaDeviceMenu,
  ParticipantPlaceholder,
  TrackToggle,
  usePersistentUserChoices,
  usePreviewTracks,
} from '@livekit/components-react';
import { BackgroundBlur, VirtualBackground } from '@livekit/track-processors';
import { styled } from '@mui/material/styles';
import { selectIsModerator, setShowLoginPage } from '../../redux/slices/auth';
import {
  selectHasBackgroundBlur,
  selectHasVirtualBackground,
  selectIsPresentGo,
  selectVirtualBackground,
} from '../../redux/slices/room';
import { BackgroundEffect } from '../BackgroundEffect/BackgroundEffect';
import AudioOutputSelect from './AudioOutput';
import { getWindowSizeClass } from './utils';

const Buttons = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
});

/**
 * Props for the PreJoin component.
 * @public
 */
export interface IPreJoinProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onSubmit' | 'onError'> {
  camLabel?: string;
  /** Display a debug window for your convenience. */
  debug?: boolean;
  /** Prefill the input form with initial values. */
  defaults?: Partial<LocalUserChoices>;
  disableName: boolean;
  disableVideo: boolean;
  hideLogin?: boolean;
  joinLabel?: string;
  micLabel?: string;
  onError?: (error: Error) => void;
  /** This function is called with the `LocalUserChoices` if validation is passed. */
  onSubmit?: (values: LocalUserChoices) => void;
  /**
   * Provide your custom validation function. Only if validation is successful the user choices are past to the onSubmit callback.
   */
  onValidate?: (values: LocalUserChoices) => boolean;
  /**
   * If true, user choices are persisted across sessions.
   * @defaultValue true
   * @alpha
   */
  persistUserChoices?: boolean;
  userLabel?: string;
}

/**
 * The `PreJoin` prefab component is normally presented to the user before he enters a room.
 * This component allows the user to check and select the preferred media device (camera und microphone).
 * On submit the user decisions are returned, which can then be passed on to the `LiveKitRoom` so that the user enters the room with the correct media devices.
 *
 * @remarks
 * This component is independent of the `LiveKitRoom` component and should not be nested within it.
 * Because it only access the local media tracks this component is self contained and works without connection to the LiveKit server.
 *
 * @example
 * ```tsx
 * <PreJoin />
 * ```
 * @public
 */
export function PreJoin({
  camLabel = 'Camera',
  // debug,
  defaults = {},
  disableName,
  disableVideo,
  hideLogin = false,
  joinLabel = 'Join Room',
  micLabel = 'Microphone',
  onError,
  onSubmit,
  onValidate,
  persistUserChoices = true,
  userLabel = 'Username',
  ...htmlProps
}: IPreJoinProps) {
  const isPresentGo = useSelector(selectIsPresentGo);
  const [windowWidth, setWindowWidth] = useState(750);
  const { ref, width = 750 } = useResizeDetector();
  const hasBackgroundBlur = useSelector(selectHasBackgroundBlur);
  const hasVirtualBackground = useSelector(selectHasVirtualBackground);
  const virtualBackgroundImage = useSelector(selectVirtualBackground);

  // const [hasBackgroundBlur, setHasBackgroundBlur] = useState(false);
  // const [hasVirtualBackground, setHasVirtualBackground] = useState(false);
  // const [virtualBackgroundImage, setVirtualBackgroundImage] =
  //   useState<string>();
  const [userChoices, setUserChoices] = useState(defaultUserChoices);
  const isModerator = useSelector(selectIsModerator);
  const dispatch = useDispatch();

  useEffect(() => {
    const clientWidth = ref?.current?.clientWidth;
    if (clientWidth) {
      setWindowWidth(clientWidth);
    }
  }, [ref, width]);

  // TODO: Remove and pipe `defaults` object directly into `usePersistentUserChoices` once we fully switch from type `LocalUserChoices` to `UserChoices`.
  const partialDefaults: Partial<LocalUserChoices> = {
    ...(defaults.audioDeviceId !== undefined && {
      audioDeviceId: defaults.audioDeviceId,
    }),
    ...(defaults.videoDeviceId !== undefined && {
      videoDeviceId: defaults.videoDeviceId,
    }),
    ...(defaults.audioEnabled !== undefined && {
      audioEnabled: defaults.audioEnabled,
    }),
    ...(defaults.videoEnabled !== undefined && {
      videoEnabled: defaults.videoEnabled,
    }),
    ...(defaults.username !== undefined && { username: defaults.username }),
  };

  const {
    saveAudioInputDeviceId,
    saveAudioInputEnabled,
    saveUsername,
    saveVideoInputDeviceId,
    saveVideoInputEnabled,
    userChoices: initialUserChoices,
  } = usePersistentUserChoices({
    defaults: partialDefaults,
    preventSave: !persistUserChoices,
    preventLoad: !persistUserChoices,
  });

  // Initialize device settings
  const [audioEnabled, setAudioEnabled] = useState<boolean>(
    initialUserChoices.audioEnabled,
  );
  const [videoEnabled, setVideoEnabled] = useState<boolean>(
    initialUserChoices.videoEnabled,
  );
  const [audioDeviceId, setAudioDeviceId] = useState<string>(
    initialUserChoices.audioDeviceId,
  );
  const [videoDeviceId, setVideoDeviceId] = useState<string>(
    initialUserChoices.videoDeviceId,
  );
  const [username, setUsername] = useState(initialUserChoices.username);

  // Save user choices to persistent storage.
  useEffect(() => {
    saveAudioInputEnabled(audioEnabled);
  }, [audioEnabled, saveAudioInputEnabled]);
  useEffect(() => {
    saveVideoInputEnabled(videoEnabled);
  }, [videoEnabled, saveVideoInputEnabled]);
  useEffect(() => {
    saveAudioInputDeviceId(audioDeviceId);
  }, [audioDeviceId, saveAudioInputDeviceId]);
  useEffect(() => {
    saveVideoInputDeviceId(videoDeviceId);
  }, [videoDeviceId, saveVideoInputDeviceId]);
  useEffect(() => {
    saveUsername(username);
  }, [username, saveUsername]);

  const tracks = usePreviewTracks(
    {
      audio: audioEnabled
        ? { deviceId: initialUserChoices.audioDeviceId }
        : false,
      video: videoEnabled
        ? { deviceId: initialUserChoices.videoDeviceId }
        : false,
    },
    onError,
  );

  const videoEl = useRef(null);

  const videoTrack = useMemo(
    () =>
      tracks?.filter(
        track => track.kind === Track.Kind.Video,
      )[0] as LocalVideoTrack,
    [tracks],
  );

  const facingMode = useMemo(() => {
    if (videoTrack) {
      const { facingMode } = facingModeFromLocalTrack(videoTrack);
      return facingMode;
    }
    return 'undefined';
  }, [videoTrack]);

  const audioTrack = useMemo(
    () =>
      tracks?.filter(
        track => track.kind === Track.Kind.Audio,
      )[0] as LocalAudioTrack,
    [tracks],
  );

  useEffect(() => {
    if (videoEl.current && videoTrack) {
      videoTrack.unmute();
      videoTrack.attach(videoEl.current);
    }

    return () => {
      videoTrack?.detach();
    };
  }, [videoTrack]);

  const [isValid, setIsValid] = useState<boolean>();

  const handleValidation = useCallback(
    (values: LocalUserChoices) => {
      if (typeof onValidate === 'function') {
        return onValidate(values);
      }
      return values.username !== '';
    },
    [onValidate],
  );

  useEffect(() => {
    const newUserChoices = {
      username,
      videoEnabled,
      videoDeviceId,
      audioEnabled,
      audioDeviceId,
    };
    setUserChoices(newUserChoices);
    setIsValid(handleValidation(newUserChoices));
  }, [
    username,
    videoEnabled,
    handleValidation,
    audioEnabled,
    audioDeviceId,
    videoDeviceId,
  ]);

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (handleValidation(userChoices)) {
      if (typeof onSubmit === 'function') {
        onSubmit(
          {
            ...userChoices,
            username: userChoices.username,
            videoDeviceId: disableVideo ? '' : userChoices.videoDeviceId,
            videoEnabled: disableVideo ? false : userChoices.videoEnabled,
          },
          // { hasBackgroundBlur, hasVirtualBackground, virtualBackgroundImage },
        );
      }
    } else {
      log.warn('Validation failed with: ', userChoices);
    }
  }

  useEffect(() => {
    if (videoTrack && !isFirefox && !isSafari) {
      const currentProcessor = videoTrack.getProcessor();

      if (hasVirtualBackground && virtualBackgroundImage) {
        const virtualBackground = VirtualBackground(virtualBackgroundImage);
        videoTrack?.setProcessor(virtualBackground);
      } else if (hasBackgroundBlur) {
        const blur = BackgroundBlur(20, { delegate: 'CPU' });
        videoTrack?.setProcessor(blur);
      } else if (currentProcessor) {
        videoTrack.stopProcessor();
      }
    }
  }, [
    videoTrack,
    hasBackgroundBlur,
    hasVirtualBackground,
    virtualBackgroundImage,
  ]);

  const handleSetShowLoginPage = useCallback(() => {
    dispatch(setShowLoginPage(true));
  }, [dispatch]);

  return (
    <div ref={ref} style={{ width: '100%', height: '100%' }}>
      <div
        className={`lk-prejoin ${getWindowSizeClass(windowWidth)}`}
        {...htmlProps}
      >
        {!disableVideo ? (
          <div className="lk-video-container">
            {videoTrack && videoEnabled && (
              <video
                data-lk-facing-mode={facingMode}
                height="720"
                ref={videoEl}
                width="1280"
              />
            )}
            {(!videoTrack || !videoEnabled) && (
              <div className="lk-camera-off-note">
                <ParticipantPlaceholder />
              </div>
            )}
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="lk-button-group audio">
            <TrackToggle
              initialState={audioEnabled}
              onChange={enabled => setAudioEnabled(enabled)}
              source={Track.Source.Microphone}
            >
              {micLabel}
            </TrackToggle>
            <div className="lk-button-group-menu">
              <MediaDeviceMenu
                disabled={!audioTrack}
                initialSelection={audioDeviceId}
                kind="audioinput"
                onActiveDeviceChange={(_, id) => setAudioDeviceId(id)}
                tracks={{ audioinput: audioTrack }}
              />
            </div>
          </div>
          {!disableVideo ? (
            <div className="lk-button-group video">
              <TrackToggle
                initialState={videoEnabled}
                onChange={enabled => setVideoEnabled(enabled)}
                source={Track.Source.Camera}
              >
                {camLabel}
              </TrackToggle>
              <div className="lk-button-group-menu">
                <MediaDeviceMenu
                  disabled={!videoTrack}
                  initialSelection={videoDeviceId}
                  kind="videoinput"
                  onActiveDeviceChange={(_, id) => setVideoDeviceId(id)}
                  tracks={{ videoinput: videoTrack }}
                />
              </div>
            </div>
          ) : null}
          {!isFirefox ? <AudioOutputSelect /> : null}
          {!isFirefox && !isSafari && !disableVideo ? (
            <BackgroundEffect hasVideoTrack={!!videoTrack} isPreRoll={true} />
          ) : null}
          <form className="lk-username-container">
            {!disableName ? (
              <input
                autoComplete="off"
                className="lk-form-control"
                defaultValue={username}
                id="username"
                name="username"
                onChange={inputEl => setUsername(inputEl.target.value)}
                placeholder={userLabel}
                type="text"
              />
            ) : null}
            <Buttons>
              <button
                className="lk-button lk-join-button"
                disabled={!isValid}
                onClick={handleSubmit}
                type="submit"
              >
                {joinLabel}
              </button>
              {isModerator || isPresentGo || hideLogin ? null : (
                <button
                  className="lk-button lk-login-button"
                  onClick={handleSetShowLoginPage}
                  type="submit"
                >
                  Login
                </button>
              )}
            </Buttons>
          </form>
        </div>
      </div>
    </div>
  );
}
