/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { prerollUrl } from '../../../../utils/constants';
import { useSetLiveView } from '../../hooks/useSetLiveView';
import { FullScreen, FullscreenVideo } from '../../Styled';

interface IPrerollView {
  locale?: string;
  webcamGoPreRollVideoUrl?: string;
}

export const PrerollView = ({
  locale,
  webcamGoPreRollVideoUrl,
}: IPrerollView) => {
  const videoUrl = locale
    ? prerollUrl.replace('[[locale]]', locale)
    : webcamGoPreRollVideoUrl;

  useSetLiveView({
    component: 'preroll',
    videoTracks: [],
  });

  return (
    <FullScreen>
      {videoUrl && (
        <FullscreenVideo autoPlay={true} id="preRollVideo" loop={true}>
          <source src={videoUrl} type="video/mp4" />
        </FullscreenVideo>
      )}
    </FullScreen>
  );
};
