import { useCallback, useState } from 'react';
import { ErrorDialog } from '../components';

export const useErrorDialog = (errorMessage: string, errorTitle?: string) => {
  const [errorMsg, setErrorMsg] = useState(errorMessage);
  const [title, setErrorTitle] = useState(errorTitle);
  const [error, showError] = useState(false);

  const handleHideError = useCallback(() => showError(false), []);

  const Error = () => (
    <ErrorDialog onConfirm={handleHideError} open={error} title={title}>
      {errorMsg}
    </ErrorDialog>
  );

  return {
    showError,
    setErrorMsg,
    setErrorTitle,
    Error,
  };
};
