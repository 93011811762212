/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from '@mui/material/styles';
import { IConferenceParticipant } from '../../redux/streamGoApi';

interface IParticipantImage {
  className?: string;
  streamGoParticipant: IConferenceParticipant;
}

export const ParticipantImageStyled: any = styled('img')({
  width: '100%',
  height: '100%',
});

export const ParticipantImage = ({
  className,
  streamGoParticipant,
}: IParticipantImage) => (
  <ParticipantImageStyled
    alt={`${streamGoParticipant?.DisplayName} Img`}
    className={className}
    src={
      streamGoParticipant?.ParticipantImgUrl || streamGoParticipant?.StreamGoImg
    }
  />
);
