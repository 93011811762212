import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface IActionButton {
  buttonText: string;
  color: 'secondary' | 'primary' | 'success' | 'error' | 'info' | 'warning';
  onClick: () => void;
}

export interface IActionDialog {
  actionButtons: IActionButton[];
  children: JSX.Element | JSX.Element[];
  onCancel: () => void;
  open: boolean;
  title: string;
}

export const ActionDialog = ({
  actionButtons,
  children,
  onCancel,
  open,
  title,
}: IActionDialog) => {
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          textAlign: 'center',
          backgroundColor: '#424242',
          color: 'white',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>{children}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel} variant="contained">
          Cancel
        </Button>
        {actionButtons.map(({ buttonText, color, onClick }) => (
          <Button
            color={color}
            key={buttonText}
            onClick={onClick}
            variant="contained"
          >
            {buttonText}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};
