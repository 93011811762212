import { useState, useCallback, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { ColorPicker } from '../ColourPicker';
import { IVideoBorder } from '../types';

export const useBorderSettings = (initialVideoBorder?: IVideoBorder) => {
  const [borderChecked, setBorderChecked] = useState<boolean>(
    !!initialVideoBorder,
  );
  const [borderColour, setBorderColour] = useState<string>(
    initialVideoBorder?.color || '#000000',
  );
  const [borderWidth, setBorderWidth] = useState<number>(
    initialVideoBorder?.width || 2,
  );

  const handleBorderCheckedChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setBorderChecked(e.target.checked),
    [],
  );

  const handleBorderWidthChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setBorderWidth(parseInt(e.target.value, 10)),
    [],
  );

  useEffect(() => {
    if (initialVideoBorder) {
      setBorderChecked(true);
      setBorderColour(initialVideoBorder.color);
      setBorderWidth(initialVideoBorder.width);
    } else {
      setBorderChecked(false);
      setBorderColour('#000000');
      setBorderWidth(2);
    }
  }, [initialVideoBorder]);

  const getModel = () =>
    borderChecked ? { color: borderColour, width: borderWidth } : undefined;

  return {
    borderBlocks: [
      {
        title: 'Active',
        element: (
          <Checkbox
            checked={borderChecked}
            onChange={handleBorderCheckedChanged}
          />
        ),
      },
      {
        active: borderChecked,
        title: 'Colour',
        element: (
          <ColorPicker
            colorSelected={borderColour}
            setColor={setBorderColour}
          />
        ),
      },
      {
        active: borderChecked,
        title: 'Thickness',
        element: (
          <>
            <input
              className="slider-color"
              max={20}
              min={0}
              onChange={handleBorderWidthChanged}
              step={1}
              type="range"
              value={borderWidth}
            />
            <span>{borderWidth}px</span>
          </>
        ),
      },
    ],
    getBorderModel: getModel,
  };
};
