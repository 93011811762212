import { useState, useCallback, useEffect } from 'react';
import { useErrorDialog } from '../../../hooks';
import { IUploadImageToS3 } from '../../../redux/streamGoApi';
import { BackgroundUploader } from '../BackgroundUploader';
import { ElementPosition } from '../enums';
import { getBase64StringForUpload } from '../helpers';
import { ILogo } from '../types';

interface IUseLogoSettings {
  initialLogo?: ILogo;
  roomId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadImageToS3: (uploadSettings: IUploadImageToS3) => Promise<any>; // TODO - fix type
}

const logoPositions = [
  {
    name: 'Top Left',
    value: ElementPosition.TopLeft,
  },
  {
    name: 'Top Right',
    value: ElementPosition.TopRight,
  },
  {
    name: 'Bottom Left',
    value: ElementPosition.BottomLeft,
  },
  {
    name: 'Bottom Right',
    value: ElementPosition.BottomRight,
  },
];

export const useLogoSettings = ({
  initialLogo,
  roomId,
  uploadImageToS3,
}: IUseLogoSettings) => {
  const { Error, showError } = useErrorDialog(
    'There was an error uploading your logo',
  );
  const [logoUrl, setLogoUrl] = useState<string | undefined>(initialLogo?.url);
  const [uploadingLogo, setUploadingLogo] = useState<boolean>(false);
  const [logoPosition, setLogoPosition] = useState<ElementPosition>(
    initialLogo?.position || ElementPosition.TopLeft,
  );
  const [logoWidth, setLogoWidth] = useState<number>(initialLogo?.width || 20);

  const handleCheckChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setLogoPosition(value as ElementPosition);
    },
    [],
  );

  const uploadLogoImage = useCallback(
    async (file: FileList) => {
      setUploadingLogo(true);
      const BackgroundBase64 = await getBase64StringForUpload(file);
      const url = await uploadImageToS3({ BackgroundBase64, roomId });
      if (url && 'data' in url && url.data) {
        setLogoUrl(url.data);
      } else {
        showError(true);
      }
      setUploadingLogo(false);
    },
    [roomId, showError, uploadImageToS3],
  );

  const handleSetLogoWidth = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setLogoWidth(parseInt(e.target.value, 10)),
    [],
  );

  useEffect(() => {
    if (initialLogo) {
      setLogoUrl(initialLogo.url);
      setLogoPosition(initialLogo.position);
      setLogoWidth(initialLogo.width);
    }
  }, [initialLogo]);

  const getModel = () =>
    logoUrl
      ? { url: logoUrl, width: logoWidth, position: logoPosition }
      : undefined;

  return {
    logoBlocks: [
      {
        title: 'Logo Image',
        element: (
          <>
            <BackgroundUploader
              backgroundUrl={logoUrl}
              loading={uploadingLogo}
              setBackgroundUrl={setLogoUrl}
              uploadImage={uploadLogoImage}
            />
            <Error />
          </>
        ),
      },
      {
        active: !!logoUrl,
        title: 'Width %',
        element: (
          <>
            <input
              className="slider-color"
              max={50}
              min={5}
              onChange={handleSetLogoWidth}
              step={5}
              type="range"
              value={logoWidth}
            />
            <span>{logoWidth}%</span>
          </>
        ),
      },
      {
        active: !!logoUrl,
        title: 'Logo position',
        element: (
          <>
            {logoPositions.map(position => (
              <div className="dynamic-layout__logo" key={position.value}>
                <input
                  checked={position.value === logoPosition}
                  id={position.value}
                  name="logoPosition"
                  onChange={handleCheckChanged}
                  type="radio"
                  value={position.value}
                />
                <label htmlFor={position.value}>{position.name}</label>
              </div>
            ))}
          </>
        ),
      },
    ],
    getLogoModel: getModel,
  };
};
