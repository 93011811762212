import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  LocalParticipant,
  Participant,
  RemoteParticipant,
} from 'livekit-client';
import { MicDisabledIcon, MicIcon } from '@livekit/components-react';
import { selectIsModerator } from '../../../redux/slices/auth';
import { useParticipantMuteMutation } from '../../../redux/streamGoApi';

interface IMicrophoneIcon {
  participant?: Participant;
  roomId: string;
}

const getRemoteParticipantAudioTrackId = (participant?: Participant) => {
  if (participant?.audioTrackPublications.size === 0) {
    return undefined;
  }

  const firstEntry = participant?.audioTrackPublications.entries().next().value;
  const firstKey = firstEntry[0];

  return firstKey;
};

export const MicrophoneIcon = ({ participant, roomId }: IMicrophoneIcon) => {
  const isModerator = useSelector(selectIsModerator);
  const [setIsMute] = useParticipantMuteMutation();
  const trackId = getRemoteParticipantAudioTrackId(participant);
  const toggleParticipantMicrophone = useCallback(() => {
    if (!participant) return;

    if (participant.isLocal) {
      const local = participant as LocalParticipant;
      local.setMicrophoneEnabled(!local.isMicrophoneEnabled);
    } else if (trackId && isModerator) {
      const remote = participant as RemoteParticipant;
      setIsMute({
        roomId,
        identity: participant.identity,
        trackId,
        isMuted: remote.isMicrophoneEnabled,
      });
    }
  }, [isModerator, participant, roomId, setIsMute, trackId]);

  return (
    <>
      {participant?.isMicrophoneEnabled ? (
        <MicIcon
          className={`icon-on ${
            !participant?.isLocal && (!trackId || !isModerator)
              ? 'disabled'
              : ''
          }`}
          onClick={toggleParticipantMicrophone}
        />
      ) : (
        <MicDisabledIcon
          className={`icon-off ${
            !participant?.isLocal && (!trackId || !isModerator)
              ? 'disabled'
              : ''
          }`}
          onClick={toggleParticipantMicrophone}
        />
      )}
    </>
  );
};
