import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { MediaDeviceMenu } from '@livekit/components-react';
import VolumeUp from '@mui/icons-material/VolumeUp';
import { setAudioOutputDeviceId } from '../../redux/slices/room';

const AudioOutputSelect = () => {
  const dispatch = useDispatch();
  const [selectedDevice, setSelectedDevice] = useState<string>('');

  useEffect(() => {
    if (selectedDevice) {
      dispatch(setAudioOutputDeviceId(selectedDevice));
    }
  }, [dispatch, selectedDevice]);

  const handleSetSelectedDeviceId = useCallback(
    (kind: MediaDeviceKind, deviceId: string) => setSelectedDevice(deviceId),
    [],
  );

  return (
    <div className="lk-button-group audio">
      <button
        aria-pressed={Boolean(selectedDevice)}
        className="lk-button"
        data-lk-enabled={Boolean(selectedDevice)}
        data-lk-source="audio-output"
      >
        <VolumeUp />
        Audio Output
      </button>
      <div className="lk-button-group-menu">
        <MediaDeviceMenu
          disabled={false}
          initialSelection={selectedDevice}
          kind="audiooutput"
          onActiveDeviceChange={handleSetSelectedDeviceId}
        ></MediaDeviceMenu>
      </div>
    </div>
  );
};

export default AudioOutputSelect;
