import ErrorOutlineTwoTone from '@mui/icons-material/ErrorOutlineTwoTone';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export const Error = () => {
  return (
    <Container maxWidth="sm">
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight="100%"
      >
        <ErrorOutlineTwoTone color="error" sx={{ fontSize: 80, mb: 2 }} />
        <Typography component="h1" gutterBottom={true} variant="h4">
          Oops! Something went wrong.
        </Typography>
        <Typography align="center" sx={{ mt: 1 }} variant="body1">
          We&apos;re sorry, but an error has occurred. Please try again later or
          contact our support team if the problem persists.
        </Typography>
      </Box>
    </Container>
  );
};
