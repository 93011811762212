/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import type { Observable } from 'rxjs';

export function useObservableState<T>(
  observable: Observable<T> | undefined,
  startWith: T,
  resetWhenObservableChanges = true,
) {
  const [state, setState] = React.useState<T>(startWith);
  React.useEffect(() => {
    if (resetWhenObservableChanges) {
      setState(startWith);
    }
    // observable state doesn't run in SSR
    if (typeof window === 'undefined' || !observable) return;
    const subscription = observable.subscribe(setState);
    return () => subscription.unsubscribe();
  }, [observable, resetWhenObservableChanges]);
  return state;
}
