import * as React from 'react';
import { setupParticipantName } from '@livekit/components-core';
import {
  UseParticipantInfoOptions,
  useEnsureParticipant,
} from '@livekit/components-react';

import { useObservableState } from './useObservableState';

/** @public */
export interface IParticipantNameProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    UseParticipantInfoOptions {}

/**
 * The `ParticipantName` component displays the name of the participant as a string within an HTML span element.
 * If no participant name is undefined the participant identity string is displayed.
 *
 * @example
 * ```tsx
 * <ParticipantName />
 * ```
 * @public
 */
export const ParticipantName = /* @__PURE__ */ React.forwardRef<
  HTMLSpanElement,
  IParticipantNameProps
>(function ParticipantName(
  { participant, ...props }: IParticipantNameProps,
  ref,
) {
  const p = useEnsureParticipant(participant);

  const { infoObserver } = React.useMemo(() => {
    return setupParticipantName(p);
  }, [p]);

  const { identity, name } = useObservableState(infoObserver, {
    name: p.name,
    identity: p.identity,
    metadata: p.metadata,
  });

  return (
    <span ref={ref} {...props}>
      {String(name !== '' ? name : identity)}
      {props.children}
    </span>
  );
});
