import { useMemo } from 'react';
import { useGetSettingsQuery } from '../../../redux/streamGoApi';
import { SettingType } from '../../../redux/streamGoApi/enums';

export const useGetSettings = () => {
  const { data } = useGetSettingsQuery();

  const settings = useMemo(() => {
    const findSetting = (setting: SettingType) =>
      data?.find(x => x.Setting === setting)?.Value;

    return {
      apiUrl: findSetting(SettingType.ApiUrl),
      pollInterval: findSetting(SettingType.PollInterval),
      postRollVideoUrl: findSetting(SettingType.WebcamGoPostRollVideoUrl),
      preRollVideoUrl: findSetting(SettingType.WebcamGoPreRollVideoUrl),
    };
  }, [data]);

  return settings;
};
