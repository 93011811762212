import { useTheme } from '@mui/material/styles';

interface ILogo {
  height?: number;
  marginBottom?: number;
}

export const Logo = ({ height = 90, marginBottom = 20 }: ILogo) => {
  const theme = useTheme();
  return (
    <img
      alt="Logo"
      src={theme.logo}
      style={{ height, marginBottom, maxWidth: '100%', objectFit: 'contain' }}
    />
  );
};
