import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Participant } from 'livekit-client';
import PushPin from '@mui/icons-material/PushPin';
import { selectIsModerator } from '../../../redux/slices/auth';
import {
  IConferenceParticipant,
  useUpdatePinnedMutation,
} from '../../../redux/streamGoApi';

interface IPinnedModeIcon {
  conferenceParticipant?: IConferenceParticipant;
  participant?: Participant;
  roomId: string;
}

export const PinnedModeIcon = ({
  conferenceParticipant,
  participant,
  roomId,
}: IPinnedModeIcon) => {
  const isModerator = useSelector(selectIsModerator);
  const [updatePinned] = useUpdatePinnedMutation();

  const isSgVmParticipant = participant?.identity
    .toLowerCase()
    .startsWith('sg_vm');

  const togglePinnedMode = useCallback(() => {
    if (participant?.sid && !isSgVmParticipant) {
      updatePinned({
        roomId,
        Participant: {
          Pinned: !conferenceParticipant?.Pinned,
          UserId: participant?.sid,
        },
      });
    }
  }, [
    conferenceParticipant?.Pinned,
    isSgVmParticipant,
    participant?.sid,
    roomId,
    updatePinned,
  ]);

  return (
    <>
      {isModerator && !isSgVmParticipant ? (
        <>
          {conferenceParticipant?.Pinned ? (
            <PushPin className="icon-on" onClick={togglePinnedMode} />
          ) : (
            <PushPin className="icon-off" onClick={togglePinnedMode} />
          )}
        </>
      ) : null}
    </>
  );
};
