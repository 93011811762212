import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export interface IConfirmationDialog {
  children: JSX.Element | JSX.Element[];
  keepMounted: boolean;
  okButtonText?: string;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  title: string;
}

export const ConfirmationDialog = ({
  children,
  okButtonText = 'Ok',
  onCancel,
  onConfirm,
  open,
  title,
}: IConfirmationDialog) => {
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          textAlign: 'center',
          backgroundColor: '#424242',
          color: 'white',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>{children}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel} variant="contained">
          Cancel
        </Button>
        <Button color="error" onClick={onConfirm} variant="contained">
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
