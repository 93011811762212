import React from 'react';
import { useSelector } from 'react-redux';
import GlobalStyles from '@mui/material/GlobalStyles';
import { selectDynamicLayout } from '../../../redux/slices/room';

interface IVideoBorderStyle {
  color: string;
  width: number;
}

const VideoBorderStyle = ({ color, width }: IVideoBorderStyle) => (
  <GlobalStyles
    styles={{
      'video.videoCam, img.videoCam': {
        border: `${width}px solid ${color}`,
        boxSizing: 'border-box',
        objectFit: 'cover',
        maxHeight: '100vh',
      },
    }}
  />
);

export const VideoBorder = () => {
  const dynamicLayout = useSelector(selectDynamicLayout) ?? {};

  if (dynamicLayout.videoBorder) {
    const { color, width } = dynamicLayout.videoBorder;
    return <VideoBorderStyle color={color} width={width} />;
  }

  return null;
};
