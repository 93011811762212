import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrackReferenceOrPlaceholder } from '@livekit/components-react';
import {
  selectLiveViews,
  selectStreamGoRoom,
  setOnScreenParticipantIds,
} from '../../../redux/slices/room';
import { StreamType } from '../enums';

interface IUseSetLiveView {
  component?: string;
  disableSetLive?: boolean;
  streamType?: StreamType;
  videoTracks: Array<TrackReferenceOrPlaceholder | undefined>;
}

export const useSetLiveView = ({
  component,
  disableSetLive = false,
  streamType = StreamType.Webcam,
  videoTracks,
}: IUseSetLiveView) => {
  const [lastSet, setLastSet] = useState('');
  const dispatch = useDispatch();
  const liveNow = useSelector(selectLiveViews);
  const data = useSelector(selectStreamGoRoom);

  useEffect(() => {
    if (!disableSetLive) {
      let ids: string[] = [];

      if (streamType === StreamType.Webcam) {
        ids = videoTracks
          .filter(
            x => x?.participant.isCameraEnabled && x.publication?.isEnabled,
          )
          .map(x => x?.participant.sid) as string[];
      }

      const strigifiedIds = JSON.stringify(ids);

      if (strigifiedIds !== lastSet && liveNow !== strigifiedIds) {
        console.log('Setting live now', strigifiedIds, component);

        dispatch(setOnScreenParticipantIds(strigifiedIds));
        setLastSet(strigifiedIds);
      }
    }
  }, [
    dispatch,
    liveNow,
    streamType,
    videoTracks,
    data?.StreamingView,
    lastSet,
    disableSetLive,
    component,
  ]);
};
