import { useEffect, useState } from 'react';

export const useImageDimension = (imageUrl?: string) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const getImageDimensions = (url: string) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setDimensions({ width: img.width, height: img.height });
    };
  };

  useEffect(() => {
    if (imageUrl) {
      getImageDimensions(imageUrl);
    }
  }, [imageUrl]);

  return dimensions;
};
