/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const Container: any = styled(Box)({
  width: '100%',
  padding: '5px',
});

export const Section: any = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '3%',
  justifyContent: 'center',
  flexDirection: 'column',
  marginBottom: '10px',
});

export const Title: any = styled(Box)({
  fontSize: '1.2em',
  fontWeight: 'bold',
});

export const Block: any = styled(Box)({
  display: 'flex',
  padding: '5px',
  justifyContent: 'space-between',
});

export const DynamicImage = styled('img')({
  maxWidth: '150px',
  maxHeight: '150px',
  margin: '10px 0',
});

export const IconButtonStyled: any = styled(IconButton)({
  background: '#41414a',
  color: 'white',
  padding: '5px',
  float: 'right',
  marginLeft: '10px',
  '&:hover': {
    background: '#5a5a69',
  },
});

export const ColorPickerButton: any = styled(Button)(() => ({
  width: '20px',
  height: '20px',
  borderRadius: '4px',
  minWidth: '20px',
  border: '1px solid rgba(255, 255, 255, 0.7)',
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const ColorPickerDropdown = styled('div')(() => ({
  marginTop: '13px',
  position: 'absolute',
  right: '-10px',
  zIndex: 2,
  '& .twitter-picker': {
    zIndex: 3,
    width: '210px!important',
    '& span div': {
      width: '25px!important',
      height: '25px!important',
    },
  },
}));

export const SavingOverlay = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1em',
  color: 'white',
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0,0,0,0.8)',
}));
