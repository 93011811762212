/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-alert */
import { useEffect } from 'react';
import { useErrorDialog } from '.';

const errorMap = {
  NotAllowedError: 'permission_denied',
  PermissionDeniedError: 'permission_denied',
  NotFoundError: 'no_camera',
  OverconstrainedError: 'in_use',
};

const statusMessages = {
  api_not_supported: 'The getUserMedia API is not supported in this browser',
  no_video_tracks: 'No video tracks available',
  available: 'Camera is available and permission granted',
  permission_denied: 'Camera permission was denied by the user',
  no_camera: 'No camera found on this device',
  in_use: 'Camera is already in use by another application',
  unknown_error: 'An unknown error occurred while accessing the camera',
};

const errorMsg = `An error occurred when joining the conference. Please make sure to allow
access to your microphone and camera. Please note - you cannot use your
camera with more than one application at a time, please ensure that no
other browser windows/applications are using the webcam at the same time`;

type ErrorType = keyof typeof statusMessages;

const checkCameraStatus = async (): Promise<ErrorType> => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    return 'api_not_supported';
  }

  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });

    if (stream.getVideoTracks().length === 0) {
      return 'no_video_tracks';
    }

    stream.getTracks().forEach(track => track.stop());
    return 'available';
  } catch (error) {
    const errorStr = error as Error;
    return (errorMap[errorStr?.name as keyof typeof errorMap] ||
      'unknown_error') as ErrorType;
  }
};

export const useCheckCameraError = (hasError: boolean) => {
  const { Error, setErrorTitle, showError } = useErrorDialog(
    errorMsg,
    'Error accessing camera',
  );

  useEffect(() => {
    if (hasError) {
      checkCameraStatus().then((status: keyof typeof statusMessages) => {
        if (status !== 'available') {
          const errorMessage = statusMessages[status] || 'Unexpected status';
          setErrorTitle(errorMessage);
          showError(true);
        }
      });
    }
  }, [hasError, setErrorTitle, showError]);

  return <Error />;
};
