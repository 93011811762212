/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-nested-callbacks */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import {
  ChatCloseIcon,
  useMaybeLayoutContext,
} from '@livekit/components-react';
import { styled } from '@mui/material/styles';
import { selectIsModerator } from '../../redux/slices/auth';
import { selectIsPresentGo } from '../../redux/slices/room';
import { useGetUserQuery } from '../../redux/streamGoApi';
import { StreamingViewType, UserRole } from '../../redux/streamGoApi/enums';
import { SettingsDynamicLayout } from './SettingsDynamicLayout';
import { SettingsMedia } from './SettingsMedia';
import { SettingsStreamingView } from './SettingsStreamingView';
import { SettingsUsers } from './SettingsUsers';

const Tabs = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-around',
  padding: '10px',
});

const settingsBase = {
  media: {
    camera: true,
    microphone: true,
    label: 'Media',
    speaker: false,
  },
  users: {
    label: 'Users',
  },
};

const settingsFull = {
  ...settingsBase,
  dynamicLayout: { label: 'Layout' },
  streamingView: { label: 'Streaming' },
  defaultView: { label: 'Default' },
};

export type ISettings = {
  defaultView?: { label: string };
  dynamicLayout?: { label: string };
  media?: {
    camera: boolean;
    label: string;
    microphone: boolean;
    speaker: boolean;
  };
  streamingView?: { label: string };
  users?: { label: string };
};

export function SettingsMenu() {
  const layoutContext = useMaybeLayoutContext();
  const { data: user } = useGetUserQuery();
  const isModerator = useSelector(selectIsModerator);
  const isPresentGo = useSelector(selectIsPresentGo);
  const isAdmin = user?.Role !== UserRole.User && isModerator;

  const settings: ISettings =
    user?.Role !== UserRole.User && !isPresentGo ? settingsFull : settingsBase;

  const tabs = useMemo(
    () => Object.keys(settings) as Array<keyof ISettings>,
    [settings],
  );

  const [activeTab, setActiveTab] = useState<keyof ISettings>(tabs[0]);

  const setTabActive = useCallback(
    (tab: keyof ISettings) => () => {
      setActiveTab(tab);
    },
    [],
  );

  const toggleSettings = useCallback(
    () => layoutContext?.widget.dispatch?.({ msg: 'toggle_settings' }),
    [layoutContext?.widget],
  );

  return (
    <div className="settings-menu">
      <div className="lk-chat-header">
        Settings - {settings[activeTab]?.label}
        <span className="lk-close-button">
          <ChatCloseIcon onClick={toggleSettings} />
        </span>
      </div>
      <Tabs>
        {tabs.map(tab =>
          settings[tab] ? (
            <button
              aria-pressed={tab === activeTab}
              className={'lk-button'}
              key={tab}
              onClick={setTabActive(tab)}
            >
              {settings[tab]?.label}
            </button>
          ) : null,
        )}
      </Tabs>
      <div className="tab-content">
        {activeTab === 'users' ? <SettingsUsers /> : null}
        {activeTab === 'media' ? <SettingsMedia settings={settings} /> : null}
        {activeTab === 'dynamicLayout' ? <SettingsDynamicLayout /> : null}
        {activeTab === 'streamingView' && isAdmin && !isPresentGo ? (
          <SettingsStreamingView
            selectedTab={StreamingViewType.StreamingView}
          />
        ) : null}
        {activeTab === 'defaultView' && isAdmin && !isPresentGo ? (
          <SettingsStreamingView
            selectedTab={StreamingViewType.StreamingViewDefault}
          />
        ) : null}
      </div>
    </div>
  );
}
