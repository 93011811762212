import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TrackReference } from '@livekit/components-core';
import { useImageDimension } from '../../../../../hooks';
import { selectConferenceParticipants } from '../../../../../redux/slices/room';
import { calculateVideoWidths } from '../utils';

interface IUseGetRightWidthPercentage {
  inline?: boolean;
  ratio: string;
  refHeight?: number;
  refWidth?: number;
  rightTrack: TrackReference;
  rightWidth?: number;
}

export const useGetRightWidthPercentage = ({
  inline,
  ratio,
  refHeight,
  refWidth,
  rightTrack,
  rightWidth,
}: IUseGetRightWidthPercentage) => {
  const streamGoParticipants = useSelector(selectConferenceParticipants);

  const streamGoParticipant = streamGoParticipants.find(
    x => x.ParticipantId === rightTrack?.participant.sid,
  );

  const imageDimensions = useImageDimension(
    streamGoParticipant?.ParticipantImgUrl,
  );

  const isCameraEnabled = rightTrack?.participant.isCameraEnabled;
  const rightRatio =
    isCameraEnabled || !imageDimensions
      ? ratio
      : `${imageDimensions?.width}_${imageDimensions?.width}`;

  const [rightW, setRightW] = useState<number>(Number(rightWidth));

  useEffect(() => {
    const dimensions =
      refWidth && refHeight
        ? {
            width: refWidth,
            height: refHeight,
          }
        : undefined;

    const newRightW = Number(
      inline
        ? calculateVideoWidths({
            rightRatio,
            dimensions,
            imageDimensions: isCameraEnabled ? undefined : imageDimensions,
          }).right
        : rightWidth,
    );

    setRightW(Number(newRightW.toFixed(1)));
  }, [
    streamGoParticipant?.ParticipantImgUrl,
    refWidth,
    refHeight,
    rightRatio,
    inline,
    imageDimensions,
    rightWidth,
    isCameraEnabled,
  ]);

  return { rightRatio, rightW };
};
