/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-nested-callbacks */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { isFirefox, isSafari } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { LocalAudioTrack, Track } from 'livekit-client';
import {
  useLocalParticipant,
  MediaDeviceMenu,
  TrackToggle,
} from '@livekit/components-react';
import Checkbox from '@mui/material/Checkbox';
import { BackgroundEffect } from '../BackgroundEffect/BackgroundEffect';
import AudioOutputSelect from '../LiveKit/AudioOutput';
import { ISettings } from './SettingsMenu';

interface ISettingsMedia {
  settings: ISettings;
}

export const SettingsMedia = ({ settings }: ISettingsMedia) => {
  // const dispatch = useDispatch();
  // const hasBackgroundBlur = useSelector(selectHasBackgroundBlur);
  // const hasVirtualBackground = useSelector(selectHasVirtualBackground);
  const { microphoneTrack } = useLocalParticipant();
  const [isNoiseFilterEnabled, setIsNoiseFilterEnabled] = useState(true);

  const setNoiseFilter = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) =>
      setIsNoiseFilterEnabled(ev.target.checked),
    [],
  );

  useEffect(() => {
    const micPublication = microphoneTrack;
    if (micPublication && micPublication.track instanceof LocalAudioTrack) {
      const currentProcessor = micPublication.track.getProcessor();
      if (currentProcessor && !isNoiseFilterEnabled) {
        micPublication.track.stopProcessor();
      } else if (!currentProcessor && isNoiseFilterEnabled) {
        import('@livekit/krisp-noise-filter')
          .then(({ isKrispNoiseFilterSupported, KrispNoiseFilter }) => {
            if (!isKrispNoiseFilterSupported()) {
              console.error(
                'Enhanced noise filter is not supported for this browser',
              );
              setIsNoiseFilterEnabled(false);
              return;
            }
            micPublication?.track
              // @ts-ignore
              ?.setProcessor(KrispNoiseFilter())
              .then(() => console.log('successfully set noise filter'));
          })
          .catch(e => console.error('Failed to load noise filter', e));
      }
    }
  }, [isNoiseFilterEnabled, microphoneTrack]);

  return (
    <>
      {settings.media && settings.media.camera && (
        <>
          <h3>Camera</h3>
          <section className="lk-button-group">
            <TrackToggle source={Track.Source.Camera}>Camera</TrackToggle>
            <div className="lk-button-group-menu">
              <MediaDeviceMenu kind="videoinput" />
            </div>
          </section>
        </>
      )}
      {settings.media && settings.media.microphone && (
        <>
          <h3>Microphone</h3>
          <section className="lk-button-group">
            <TrackToggle source={Track.Source.Microphone}>
              Microphone
            </TrackToggle>
            <div className="lk-button-group-menu">
              <MediaDeviceMenu kind="audioinput" />
            </div>
          </section>
        </>
      )}

      {!isFirefox ? (
        <>
          <h3>Speaker & Headphones</h3>
          <section>
            <AudioOutputSelect />
          </section>
        </>
      ) : null}

      <h3>Audio</h3>
      <section>
        <label htmlFor="noise-filter"> Enhanced Noise Cancellation</label>
        <Checkbox
          checked={isNoiseFilterEnabled}
          id="noise-filter"
          onChange={setNoiseFilter}
        />
      </section>
      {!isFirefox && !isSafari ? (
        <>
          <h3>Effects</h3>
          <BackgroundEffect hasVideoTrack={true} isPreRoll={false} />
        </>
      ) : null}
    </>
  );
};
