/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
import { useCallback, useRef } from 'react';
import Button from '@mui/material/Button';

import { VirtualBackgrounds } from './styled';

const indexes = [8, 7, 6, 5, 4, 3, 2, 1];

const exampleSplashScreens = indexes.map(index => ({
  name: `splash${index}`,
  image: `splash-${index}.jpg`,
}));

interface IImageUploaderProps {
  customeVirtualBackgrounds: string[];
  handleLoadVirtualBackground: (name: string) => void;
  handleSetVirtualBackground: (virtualBackground: string) => void;
  isPreRoll?: boolean;
  onImageUpload: (base64Image: string) => void;
}

export const ImageUploader = ({
  customeVirtualBackgrounds,
  handleLoadVirtualBackground,
  handleSetVirtualBackground,
  isPreRoll,
  onImageUpload,
}: IImageUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = error => {
        reject(error);
      };
    });
  };

  const handleImageUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const base64 = await convertToBase64(file);
        onImageUpload(base64);
      }
    },
    [onImageUpload],
  );

  return (
    <div>
      <Button
        color="primary"
        onClick={handleButtonClick}
        size="small"
        sx={{ mb: 2, mt: 2, mr: '5px' }}
        variant="contained"
      >
        Upload
      </Button>
      <input
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
      />
      <VirtualBackgrounds justifyContent={isPreRoll ? 'center' : 'flex-start'}>
        {customeVirtualBackgrounds.map((custom, i) => (
          <img
            alt="splash1"
            height={67}
            key={i}
            onClick={() => handleSetVirtualBackground(custom)}
            src={custom}
            width={100}
          />
        ))}
        {exampleSplashScreens.map(splash => (
          <img
            alt={splash.name}
            height={67}
            key={splash.name}
            onClick={() => handleLoadVirtualBackground(splash.name)}
            src={`https://assets.streamgo.vc/virtual-backgrounds/${splash.image}`}
            width={100}
          />
        ))}
      </VirtualBackgrounds>
    </div>
  );
};
