import { useVisualStableUpdate } from '@livekit/components-react';
import { useGetRoomStreamingDetails } from '../../StreamingViews';
import { StreamType } from '../enums';
import { useGetPinnedPresenters } from './useGetPinnedPresenters';

export const useGetTracks = () => {
  const streamingViewDetails = useGetRoomStreamingDetails();
  const { connectedVideoTracks, screenshareTracks, sgVmVideoTracks } =
    streamingViewDetails;

  const pinnedTracks = useGetPinnedPresenters({
    tracks: connectedVideoTracks,
  });

  const sortedTracks = useVisualStableUpdate(pinnedTracks, 1);

  const tracks = {
    [StreamType.Webcam]: sortedTracks[0],
    // [StreamType.Webcam1]: sortedTracks[sortedTracks.length === 2 ? 1 : 0],
    [StreamType.Slides]: sgVmVideoTracks[0],
    [StreamType.Screenshare]: screenshareTracks[0],
  };

  return {
    tracks,
    sortedTracks,
  };
};
