import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { selectIsAltView } from '../../../redux/slices/auth';
import {
  selectStreamGoRoom,
  selectLiveViews,
} from '../../../redux/slices/room';
import { ILiveNow, useUpdateLiveNowMutation } from '../../../redux/streamGoApi';

export const useBroadcastLiveView = () => {
  const isAltView = useSelector(selectIsAltView);
  const [updateLiveNow] = useUpdateLiveNowMutation();
  const liveNow = useSelector(selectLiveViews);
  const roomDetails = useSelector(selectStreamGoRoom);
  const hasChanged = liveNow !== roomDetails?.LiveNowUsers;

  const debouncedUpdateLiveNow = useDebounce(
    ({ liveNowUsers, roomId }: ILiveNow) => {
      updateLiveNow({ liveNowUsers, roomId });
    },
    500,
  );

  useEffect(() => {
    if (roomDetails?.RoomName && hasChanged && !isAltView) {
      debouncedUpdateLiveNow({
        liveNowUsers: liveNow,
        roomId: roomDetails?.RoomName,
      });
    }
  }, [liveNow, hasChanged, roomDetails, isAltView, debouncedUpdateLiveNow]);
};
