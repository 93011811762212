import React from 'react';
import { useSelector } from 'react-redux';
import { selectRoomName } from '../../redux/slices/room';
import { StreamingViewType } from '../../redux/streamGoApi/enums';
import { useGetRoom } from '../Rooms';
import { StreamingViews, useGetRoomStreamingDetails } from '../StreamingViews';

interface ISettingsStreamingView {
  hasPPT?: boolean;
  selectedTab: StreamingViewType;
}

export const SettingsStreamingView = ({
  hasPPT,
  selectedTab,
}: ISettingsStreamingView) => {
  const roomName = useSelector(selectRoomName) ?? '';
  const { data: roomDetails } = useGetRoom(roomName);
  const streamingViewOptions = useGetRoomStreamingDetails(hasPPT);

  return roomName ? (
    <StreamingViews
      roomDetails={roomDetails}
      roomId={roomName}
      streamingViewOptions={streamingViewOptions}
      streamingViewType={selectedTab}
    />
  ) : null;
};
