import { useSelector } from 'react-redux';
import { useParticipants } from '@livekit/components-react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { selectIsModerator } from '../../redux/slices/auth';
import {
  selectConferenceParticipants,
  selectRoom,
  selectRoomName,
  selectStreamGoRoom,
} from '../../redux/slices/room';
import { ParticipantAvatar } from './ParticipantAvatar';
import { ParticipantIcons } from './ParticipantIcons';

export const SettingsUsers = () => {
  const isModerator = useSelector(selectIsModerator);
  const streamGoParticipants = useSelector(selectConferenceParticipants);
  const room = useSelector(selectRoom);
  const participants = useParticipants({ room });
  const roomName = useSelector(selectRoomName) ?? '';
  const roomDetails = useSelector(selectStreamGoRoom);
  const filtered = participants
    .filter(
      participant =>
        isModerator ||
        participant.isLocal ||
        !participant.name?.toLowerCase().startsWith('sg_vm'),
    )
    .filter(participant => !participant.name?.startsWith('altViewMod'));

  return (
    <List sx={{ width: '100%' }}>
      {filtered.map(participant => (
        <ListItem key={participant.identity}>
          <ParticipantAvatar
            participant={participant}
            streamGoParticipants={streamGoParticipants}
          />
          <ListItemText
            primary={participant.name || participant.identity}
            secondary={
              <ParticipantIcons
                conferenceParticipant={roomDetails?.Participants.find(
                  x => x.ParticipantId === participant.sid,
                )}
                participant={participant}
                roomId={roomName}
              />
            }
          />
        </ListItem>
      ))}
    </List>
  );
};
