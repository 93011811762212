import { useState, useCallback, useEffect } from 'react';
import { useErrorDialog } from '../../../hooks';
import { IUploadImageToS3 } from '../../../redux/streamGoApi';
import { BackgroundUploader } from '../BackgroundUploader';
import { ColorPicker } from '../ColourPicker';
import { getBase64StringForUpload } from '../helpers';
import { IBackground } from '../types';

export interface IBackgroundSettingsProps {
  initialBackground?: IBackground;
  roomId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadImageToS3: (uploadSettings: IUploadImageToS3) => Promise<any>; // TODO fix type
}

export const useBackgroundSettings = ({
  initialBackground,
  roomId,
  uploadImageToS3,
}: IBackgroundSettingsProps) => {
  const { Error, showError } = useErrorDialog(
    'There was an error uploading your background',
  );
  const [backgroundColour, setBackgroundColour] = useState<string>(
    initialBackground?.backgroundColour || '#000000',
  );
  const [backgroundUrl, setBackgroundUrl] = useState<string>(
    initialBackground?.backgroundUrl || '',
  );
  const [uploadingBackground, setUploadingBackground] =
    useState<boolean>(false);

  const uploadImage = useCallback(
    async (file: FileList) => {
      setUploadingBackground(true);
      const BackgroundBase64 = await getBase64StringForUpload(file);
      // setBackgroundUrl(BackgroundBase64);
      const url = await uploadImageToS3({ BackgroundBase64, roomId });
      if (url && 'data' in url && url.data) {
        setBackgroundUrl(url.data);
      } else {
        showError(true);
      }
      setUploadingBackground(false);
    },
    [roomId, showError, uploadImageToS3],
  );

  useEffect(() => {
    if (initialBackground) {
      setBackgroundColour(initialBackground?.backgroundColour ?? '');
      setBackgroundUrl(initialBackground?.backgroundUrl ?? '');
    }
  }, [initialBackground]);

  const getModel = () =>
    backgroundColour || backgroundUrl
      ? { backgroundColour, backgroundUrl }
      : undefined;

  return {
    backgroundBlocks: [
      {
        title: 'Colour',
        element: (
          <ColorPicker
            colorSelected={backgroundColour}
            setColor={setBackgroundColour}
          />
        ),
      },
      {
        title: 'Background Image',
        element: (
          <>
            <BackgroundUploader
              backgroundUrl={backgroundUrl}
              loading={uploadingBackground}
              setBackgroundUrl={setBackgroundUrl}
              uploadImage={uploadImage}
            />
            <Error />
          </>
        ),
      },
    ],
    getBackgroundModel: getModel,
  };
};
