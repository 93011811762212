/* eslint-disable max-len */
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Room, RoomOptions, VideoPreset, VideoPresets } from 'livekit-client';

import { LocalUserChoices } from '@livekit/components-react';
import { setRoom } from '../../../redux/slices/room';

interface IUseGetRoomSettings {
  hq: boolean;
  userChoices?: LocalUserChoices;
}

const maxVm = new VideoPreset(1777.7777777, 1000, 3_000_000, 30);

export const useGetRoomConfig = ({
  hq = false,
  userChoices,
}: IUseGetRoomSettings) => {
  const dispatch = useDispatch();
  const roomOptions = useMemo((): RoomOptions => {
    return {
      videoCaptureDefaults: {
        deviceId: userChoices?.videoDeviceId ?? undefined,
        resolution: hq ? maxVm : VideoPresets.h720,
      },
      publishDefaults: {
        dtx: false,
        videoSimulcastLayers: hq
          ? [VideoPresets.h720]
          : [VideoPresets.h540, VideoPresets.h216],
        red: true,
        degradationPreference: hq ? 'maintain-resolution' : undefined,
      },
      audioCaptureDefaults: {
        deviceId: userChoices?.audioDeviceId ?? undefined,
      },
      adaptiveStream: { pixelDensity: 'screen' },
      dynacast: true,
      e2ee: undefined,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userChoices, hq]);

  const room = useMemo(() => new Room(roomOptions), [roomOptions]);

  useEffect(() => {
    if (userChoices) {
      dispatch(setRoom(room));
    }
  }, [dispatch, room, userChoices]);

  return room;
};
