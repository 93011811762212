export const getBase64StringForUpload = async (
  selectedFile: FileList,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      if (selectedFile.length > 0) {
        const imageFile = selectedFile[0];
        const fileReader = new FileReader();

        fileReader.onload = fileLoadedEvent => {
          const srcData = fileLoadedEvent.target?.result as string;
          const img = new Image();
          img.src = srcData;

          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (ctx) {
              const MAX_WIDTH = 800;
              const MAX_HEIGHT = 600;
              let { width } = img;
              let { height } = img;

              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              } else if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
              canvas.width = width;
              canvas.height = height;

              ctx.drawImage(img, 0, 0, width, height);

              const imgb64 = canvas.toDataURL('image/png');
              resolve(imgb64);
            } else {
              reject(new Error('Failed to get canvas context'));
            }
          };
        };
        fileReader.readAsDataURL(imageFile);
      } else {
        resolve('');
      }
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
};
