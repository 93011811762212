import { TrackReference } from '@livekit/components-react';
import { NameTag } from '../../../DynamicLayout/StreamingView';
import { StreamType } from '../../enums';
import { useGetTracks } from '../../hooks';
import { useSetLiveView } from '../../hooks/useSetLiveView';
import { FullScreenVideoTrack } from '../../Styled';

interface ISingleTrackView {
  disableSetLive?: boolean;
  streamType: StreamType;
}

export const SingleTrackView = ({
  disableSetLive = false,
  streamType,
}: ISingleTrackView) => {
  const { tracks } = useGetTracks();
  const videoTrack = tracks[streamType];

  useSetLiveView({
    component: 'singleTrackView',
    disableSetLive,
    streamType,
    videoTracks: [videoTrack],
  });

  if (!videoTrack) {
    return null;
  }

  return (
    <>
      <FullScreenVideoTrack
        className={streamType === StreamType.Webcam ? 'videoCam' : undefined}
        trackRef={videoTrack as TrackReference}
      />
      {streamType === StreamType.Webcam ? (
        <NameTag participant={videoTrack.participant} />
      ) : null}
    </>
  );
};
