import { useCallback } from 'react';
import { LocalParticipant, Participant } from 'livekit-client';
import {
  CameraDisabledIcon,
  CameraIcon as CamIcon,
} from '@livekit/components-react';

interface ICameraIcon {
  participant?: Participant;
}

export const CameraIcon = ({ participant }: ICameraIcon) => {
  const toggleParticipantCamera = useCallback(() => {
    if (!participant) return;

    if (participant.isLocal) {
      const local = participant as LocalParticipant;
      local.setCameraEnabled(!local.isCameraEnabled);
    }
  }, [participant]);

  return (
    <>
      {participant?.isCameraEnabled ? (
        <CamIcon
          className={`icon-on ${!participant?.isLocal ? 'disabled' : ''}`}
          onClick={toggleParticipantCamera}
        />
      ) : (
        <CameraDisabledIcon
          className={`icon-off ${!participant?.isLocal ? 'disabled' : ''}`}
          onClick={toggleParticipantCamera}
        />
      )}
    </>
  );
};
