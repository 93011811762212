/* eslint-disable @typescript-eslint/no-explicit-any */
import Settings from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';

interface IVirtualBackgroundsProps {
  justifyContent?: string;
}

export const VirtualBackgrounds = styled('div')<IVirtualBackgroundsProps>(
  ({ justifyContent = 'center' }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
    justifyContent,
    gap: 10,
    maxWidth: '100%',
    maxHeight: '222px',
    overflow: 'auto',
    flexWrap: 'wrap',
    '> img': {
      width: '100px',
      height: '67px',
      '&:hover': {
        opacity: 0.8,
        cursor: 'pointer',
      },
    },
  }),
);

export const BackgroundEffectsContainer = styled('div')({
  '.MuiCheckbox-root': {
    padding: '3px 10px',
  },
});

export const SettingsIcon: any = styled(Settings)(({ theme }) => ({
  position: 'relative',
  top: '8px',
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));
