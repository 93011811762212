/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  StreamingView,
  StreamingViewType,
} from '../../redux/streamGoApi/enums';
import { ConfirmationDialog } from '../Form';
import {
  streamingViewTitle,
  streamingViewsDesc,
  streamingViewsImage,
} from './constants';

export const StreamingViewConfirm: any = styled(Box)`
  ${({ theme }) => `
    div {
      padding: 10px 0; 
      > img {
       max-width: 100%;
      }
    }

    b {
      color: ${theme.palette.primary.main}
    }

    span {
      color: red;
      font-weight: bold;
    }
  `}
`;

export interface IStreamingViewConfirmation {
  handleCancel: () => void;
  handleConfirm: () => void;
  open: boolean;
  proposedStreamingView: StreamingView | undefined;
  streamingViewType: StreamingViewType;
}

export const StreamingViewConfirmation = ({
  handleCancel,
  handleConfirm,
  open,
  proposedStreamingView,
  streamingViewType,
}: IStreamingViewConfirmation) => {
  return (
    <ConfirmationDialog
      keepMounted={true}
      okButtonText="Yes - I'm sure"
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      open={open}
      title="Are you sure?"
    >
      <StreamingViewConfirm>
        <div>
          Are you sure you want to change{' '}
          {streamingViewTitle[streamingViewType]} to{' '}
          <b>{streamingViewsDesc[Number(proposedStreamingView)]}</b>?
        </div>
        <div>
          <img
            alt={streamingViewsDesc[Number(proposedStreamingView)]}
            src={streamingViewsImage[Number(proposedStreamingView)]}
          />
        </div>
        {streamingViewType === StreamingViewType.StreamingView ? (
          <span>
            Please note, this will change the streaming view straight away
          </span>
        ) : null}
      </StreamingViewConfirm>
    </ConfirmationDialog>
  );
};
