export const getWindowSizeClass = (width: number) => {
  if (width < 500) {
    return 'screen-500';
  }
  if (width < 750) {
    return 'screen-750';
  }
  if (width < 1000) {
    return 'screen-1000';
  }
  if (width < 1250) {
    return 'screen-1250';
  }
  if (width < 1500) {
    return 'screen-1500';
  }

  return '';
};
