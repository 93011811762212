import { StreamingView } from '../StreamingView';

interface IGetStreamingViews {
  filteredViews: number[];
  sectionViews: number[];
  selectedStreamingView?: number;
  setSelectedStreamingView: (view: number) => void;
  width: number;
}

export const getStreamingViews = ({
  filteredViews,
  sectionViews,
  selectedStreamingView,
  setSelectedStreamingView,
  width,
}: IGetStreamingViews) =>
  sectionViews
    .filter(streamingView =>
      filteredViews.find(filteredView => filteredView === streamingView),
    )
    .map(streamingView => (
      <StreamingView
        key={streamingView}
        selectedStreamingView={selectedStreamingView}
        setSelectedStreamingView={setSelectedStreamingView}
        streamingView={streamingView}
        width={width}
      />
    ));
