/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LocalUserChoices,
  formatChatMessageLinks,
} from '@livekit/components-react';
import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  selectLiveKitRoomProps,
  selectLiveKitToken,
  setLiveKitRoomProps,
} from '../../redux/slices/auth';
import {
  selectHasBackgroundBlur,
  selectHasVirtualBackground,
  selectVirtualBackground,
  selectQueryString,
} from '../../redux/slices/room';
import { Loading } from '../Form';
import { VideoConference } from '../LiveKit';
import { SettingsMenu } from '../Settings';
import { useGetRoom } from './hooks';
import { CurrentUserLive } from './LiveKitRoomStreamGo';

const VideoConferenceStyled = styled(Box, {
  shouldForwardProp: props => props !== 'currentUserLive',
})<{
  currentUserLive: boolean;
}>(
  ({ currentUserLive }) => `
    width: 100%;
    height: 100%;
    
    .lk-control-bar {
      background-color: ${currentUserLive ? 'red' : ''}
    }
    `,
);

interface IActiveRoomProps {
  region?: string;
  roomName: string;
  userChoices: LocalUserChoices;
}

export const ActiveRoom = ({ roomName, userChoices }: IActiveRoomProps) => {
  useGetRoom(roomName);
  const dispatch = useDispatch();
  const token = useSelector(selectLiveKitToken);
  const liveKeyRoomProps = useSelector(selectLiveKitRoomProps);
  const { hq } = useSelector(selectQueryString);
  const [currentUserLive, setCurrentUserLive] = useState(false);
  const hasBackgroundBlur = useSelector(selectHasBackgroundBlur);
  const hasVirtualBackground = useSelector(selectHasVirtualBackground);
  const virtualBackground = useSelector(selectVirtualBackground);

  useEffect(() => {
    if (roomName && userChoices && !liveKeyRoomProps) {
      const liveKitRoomProps = {
        hq,
        roomName,
        userChoices,
        userConfig: {
          hasBackgroundBlur,
          hasVirtualBackground,
          virtualBackground,
        },
      };

      dispatch(setLiveKitRoomProps(liveKitRoomProps));
    }
  }, [
    dispatch,
    hasBackgroundBlur,
    hasVirtualBackground,
    virtualBackground,
    hq,
    liveKeyRoomProps,
    roomName,
    userChoices,
  ]);

  return (
    <VideoConferenceStyled currentUserLive={currentUserLive}>
      <>
        {token ? (
          <>
            <VideoConference
              chatMessageFormatter={formatChatMessageLinks}
              setCurrentUserLive={setCurrentUserLive}
              SettingsComponent={SettingsMenu}
            />
            {currentUserLive ? (
              <CurrentUserLive>
                <CircleIcon /> CAM LIVE
              </CurrentUserLive>
            ) : null}
          </>
        ) : (
          <Loading />
        )}
      </>
    </VideoConferenceStyled>
  );
};
