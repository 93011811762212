import { useSelector } from 'react-redux';
import { Participant } from 'livekit-client';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { selectIsModerator } from '../../redux/slices/auth';
import { selectQueryString, selectStreamGoRoom } from '../../redux/slices/room';
import { IConferenceParticipant } from '../../redux/streamGoApi';
import {
  CameraIcon,
  MicrophoneIcon,
  PinnedModeIcon,
  PresenterImageIcon,
  SpeedTestIcon,
} from './Icons';

interface IParticipantIcons {
  conferenceParticipant?: IConferenceParticipant;
  participant?: Participant;
  roomId: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IconsContainer: any = styled(Box, {
  shouldForwardProp: props => props !== 'isModerator',
})(() => ({
  marginTop: '2px',
  display: 'flex',
  '& > svg': {
    marginRight: '4px',
    height: 20,
    width: 20,
    cursor: 'pointer',
  },
  '.MuiSvgIcon-root': {
    position: 'relative',
    top: '-2px',
  },
}));

export const ParticipantIcons = ({
  conferenceParticipant,
  participant,
  roomId,
}: IParticipantIcons) => {
  const isModerator = useSelector(selectIsModerator);
  const streamGoRoom = useSelector(selectStreamGoRoom);
  const { video } = useSelector(selectQueryString);

  return (
    <IconsContainer isModerator={isModerator}>
      <MicrophoneIcon participant={participant} roomId={roomId} />
      {video ? <CameraIcon participant={participant} /> : null}
      <PinnedModeIcon
        conferenceParticipant={conferenceParticipant}
        participant={participant}
        roomId={roomId}
      />
      <PresenterImageIcon
        conferenceParticipant={conferenceParticipant}
        participant={participant}
        roomId={roomId}
      />
      <SpeedTestIcon
        conferenceParticipant={conferenceParticipant}
        isModerator={isModerator}
        participant={participant}
        streamGoRoom={streamGoRoom}
      />
    </IconsContainer>
  );
};
